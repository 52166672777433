import * as React from 'react';

import type { BaseConfirmProps } from './BaseConfirm';
import BaseConfirm from './BaseConfirm';
import { useInitialConfirmFocus } from './Confirm.hooks';

interface ConfirmInfoProps extends BaseConfirmProps {}

/**
 * Confirm modal with no buttons other that close X button in the corner.
 * Standard notification message.
 */
const ConfirmInfo = (props: ConfirmInfoProps) => {
    const buttonRef = useInitialConfirmFocus(props.show);

    return (
        <BaseConfirm
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            closeButtonRef={buttonRef}
        />
    );
};

export default ConfirmInfo;
