import type {
    ApiLoopCurrentPromotionsRequest,
    ApiLoopRelatedProductsMultipleRequest,
    ApiLoopRelatedProductsRequest,
} from '../../../search/api/searchRequests';
import { productApi } from '../../api/products/productApi';
import userFlow from '../userFlow';
import { DirectProductProvider } from './directProductProvider';
import { mapLoopProduct } from './mapProduct';

const getProductById = async (productId: string, storeId: string): Promise<ApiProduct | null> => {
    const directResponse = await getProductsByIds([productId], storeId);
    return directResponse?.[0] || null;
};

const getProductsByIds = async (productIds: string[], storeId: string): Promise<ApiProduct[]> => {
    const customerGroups = await userFlow.getUserCustomerGroups();
    const response = await DirectProductProvider.instance.getItemsById(
        productIds,
        storeId,
        customerGroups,
    );
    const mapped = response?.map(mapLoopProduct);
    return mapped;
};

const getProductsByIdsMap = async (productIds: string[], storeId: string) => {
    const customerGroups = await userFlow.getUserCustomerGroups();
    const response = await DirectProductProvider.instance.getItemsMap(
        productIds,
        storeId,
        customerGroups,
    );
    return response;
};

const getProductsForPromotion = async (
    promotionId: string,
    pageSize: number,
    storeId: string,
    overwritePersonalizeCampaigns?: boolean,
) => {
    const loopRequest: ApiLoopCurrentPromotionsRequest = {
        promotionId,
        resultsOptions: {
            skip: 0,
            take: pageSize,
        },
        customData: {
            personalizeCampaigns: !!overwritePersonalizeCampaigns,
        },
    };

    const customerGroups = await userFlow.getUserCustomerGroups();
    const response = await productApi.getProductsForPromotionLoop(
        loopRequest,
        storeId,
        customerGroups,
    );
    const products = response.results.items?.map(mapLoopProduct) || [];

    return {
        products,
        totalItems: response.results.count,
    };
};

const getSustainabilityDeclaration = async (productId: string) => {
    const response = await productApi.getSustainabilityDeclaration(productId);
    return response;
};

const getRelatedProducts = async (productId: string, storeId: string, pageSize: number = 10) => {
    const requestBody: ApiLoopRelatedProductsRequest = {
        entity: {
            id: productId,
            type: 'Product',
        },
        resultsOptions: {
            skip: 0,
            take: pageSize,
        },
    };
    const customerGroups = await userFlow.getUserCustomerGroups();
    const response = await productApi.getRelatedProducts(requestBody, storeId, customerGroups);
    return response;
};

const getRelatedProductsMultiple = async (
    productIds: string[],
    storeId: string,
    pageSize: number = 10,
) => {
    const requestBody: ApiLoopRelatedProductsMultipleRequest = productIds.map((productId) => ({
        entity: {
            id: productId,
            type: 'Product',
        },
        resultsOptions: {
            skip: 0,
            take: pageSize,
        },
    }));
    const customerGroups = await userFlow.getUserCustomerGroups();
    const response = await productApi.getRelatedProductsMultiple(
        requestBody,
        storeId,
        customerGroups,
    );
    return response;
};

export const productFlow = {
    getProductById,
    getProductsByIds,
    getProductsByIdsMap,
    getProductsForPromotion,
    getRelatedProducts,
    getSustainabilityDeclaration,
    getRelatedProductsMultiple,
};
