import classnames from 'classnames';
import * as React from 'react';
import { useContext } from 'react';

import styles from './Modal.module.scss';
import { ModalContextState } from './ModalContext';

interface ModalHeaderProps extends React.HtmlHTMLAttributes<HTMLHeadingElement> {}

const ModalHeader = React.forwardRef<HTMLHeadingElement, React.PropsWithChildren<ModalHeaderProps>>(
    ({ children, ...props }, ref) => {
        const modalContext = useContext(ModalContextState);

        return (
            // TODO: Use Heading component when moved to packages
            <h1
                ref={ref}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                id={modalContext?.headerId}
                aria-atomic="true"
                className={classnames(props.className, styles.ModalHeader)}
            >
                {children}
            </h1>
        );
    },
);

export default ModalHeader;
