import classNames from 'classnames';
import * as React from 'react';

import styles from './Snackbar.module.scss';

interface SnackbarProps {
    children?: React.ReactNode;
    actionSlot?: React.ReactNode;
    size: SnackbarSizes;
    style?: React.CSSProperties;
}

type SnackbarSizes = 48;

const Snackbar = (props: SnackbarProps) => {
    const classes = classNames(
        styles.Snackbar,
        props.size === 48 && styles.Size48,
        !!props.actionSlot && styles.SnackbarWithAction,
        styles.Show,
    );

    return (
        <div className={styles.Container}>
            <div className={classes} style={props.style}>
                {props.children}
                {props.actionSlot && <div className={styles.Action}>{props.actionSlot}</div>}
            </div>
        </div>
    );
};

export default Snackbar;
