import type { AnalyticsListOptions } from '../../../../../analytics/analyticsHandler';
import { failSilently } from '../../utils/failSilently';
import { REPLACED_ITEM_LIST_NAME } from '../items';
import {
    getGaChangedItems,
    getGaMinimalProductKey,
    getGaProductsWithVariantsList,
    getGaProductsWithVariantsMap,
    getGaTotalProductsValue,
    mapProductToGaEcommerceItem,
} from '../items/ga4Items.utils';
import { mapGaList } from '../shared/ga4SharedMappers';
import { sendGA4Event } from '../shared/sendGa4Event';

const viewCart = async (
    products: { productId: string; variantId: string | undefined; quantity: number }[],
    totalValue: number | undefined,
    storeId: string,
) => {
    const productsWithVariantsAndQuantity = await getGaProductsWithVariantsList(products, storeId);
    const mappedItems = productsWithVariantsAndQuantity.map(({ productData, inputData }) =>
        mapProductToGaEcommerceItem(productData, inputData.quantity, inputData.variantId),
    );

    const event: ViewCartEvent = {
        event: 'view_cart',
        ecommerce: {
            currency: 'SEK',
            value: totalValue,
            items: mappedItems,
        },
    };

    sendGA4Event(event);
};

const addToWishlist = async (
    products: { productId: string; variantId: string | undefined; quantity: number }[],
    storeId: string,
) => {
    const productsWithVariantsAndQuantity = await getGaProductsWithVariantsList(products, storeId);
    const mappedItems = productsWithVariantsAndQuantity.map(({ productData, inputData }) =>
        mapProductToGaEcommerceItem(productData, inputData.quantity, inputData.variantId),
    );

    const event: AddToWishlistEvent = {
        event: 'add_to_wishlist',
        ecommerce: {
            items: mappedItems,
        },
    };

    sendGA4Event(event);
};

const addProductItems =
    (analyticsOptions: ModifyCartAnalyticsOptions | null) =>
    (items: (ApiProduct & { quantity: number; selectedVariantId?: string; index?: number })[]) => {
        const list = (analyticsOptions?.list && mapGaList(analyticsOptions.list)) || undefined;
        const mappedItems = items.map((item) => ({
            ...mapProductToGaEcommerceItem(item, item.quantity, item.selectedVariantId, item.index),
            item_list_id: list?.item_list_id,
            item_list_name: list?.item_list_name,
        }));

        const event: AddToCartEvent = {
            event: 'add_to_cart',
            ecommerce: {
                currency: 'SEK',
                value: getGaTotalProductsValue(mappedItems),
                ...{
                    recipe_list_id: analyticsOptions?.recipe_list_id,
                    recipe_list_name: analyticsOptions?.recipe_list_name,
                    recipe_type: analyticsOptions?.recipe_type,
                    recipe_origin: analyticsOptions?.recipe_origin,
                    order_status: analyticsOptions?.order_status,
                },
                items: mappedItems,
            },
        };

        sendGA4Event(event);
    };

const removeProductItems = (
    items: (ApiProduct & { quantity: number; selectedVariantId?: string; index?: number })[],
    analyticsOptions: ModifyCartAnalyticsOptions | null,
) => {
    const list = (analyticsOptions?.list && mapGaList(analyticsOptions.list)) || undefined;

    const mappedItems = items.map((item) => ({
        ...mapProductToGaEcommerceItem(item, item.quantity, item.selectedVariantId, item.index),
        item_list_id: list?.item_list_id,
        item_list_name: list?.item_list_name,
    }));

    const event: RemoveFromCartEvent = {
        event: 'remove_from_cart',
        type: analyticsOptions?.removalActionType || 'remove',
        ecommerce: {
            currency: 'SEK',
            value: getGaTotalProductsValue(mappedItems),
            items: mappedItems,
        },
    };

    sendGA4Event(event);
};

const replacedItemsAddItems = (
    _products: {
        productId: string;
        variantId: string | undefined;
        quantity: number;
    }[],
    storeId: string,
) => addToCart(_products, storeId, { list: REPLACED_ITEM_LIST_NAME });

const replacedItemsRemoveItems = (
    _products: {
        productId: string;
        variantId: string | undefined;
        quantity: number;
    }[],
    storeId: string,
) => removeFromCart(_products, storeId, { removalActionType: 'lost from cart' });

const miniCartRecipeAccordionClick = (newState: 'collapse' | 'expand', recipeName: string) => {
    const event: MiniCartRecipeAccordionClick = {
        event: 'minicart_recipe_accordion_click',
        recipe_name: recipeName.toLowerCase(),
        type: newState,
    };

    sendGA4Event(event);
};

const cartRecipeSubstituteTriggerClick = (param: {
    productId: string;
    productName: string;
    recipeName: string;
}) => {
    const event: CartRecipeSubstituteTriggerClick = {
        event: 'cart_change_product_click',
        item_id: param.productId,
        item_name: param.productName.toLowerCase(),
        recipe_name: param.recipeName.toLowerCase(),
        button_text: 'byt vara',
    };

    sendGA4Event(event);
};

const modifyCartItems = async (
    inputProductData: {
        index?: number;
        productId: string;
        variantId: string | undefined;
        newQuantity: number;
        previousQuantity: number;
    }[],
    storeId: string,
    analyticsOptions: ModifyCartAnalyticsOptions | null,
) => {
    const productsWithVariants = await getGaProductsWithVariantsMap(inputProductData, storeId);

    // split products to add and remove, based on values of newQuantity and previousQuantity
    const { productsToAdd, productsToRemove } = inputProductData.reduce(
        (acc, { productId, variantId, previousQuantity, newQuantity, index }) => {
            const product = productsWithVariants.get(
                getGaMinimalProductKey({ productId, variantId }),
            );

            if (!product) return acc;

            if (newQuantity > previousQuantity) {
                return {
                    ...acc,
                    productsToAdd: [
                        ...acc.productsToAdd,
                        {
                            ...product,
                            selectedVariantId: variantId,
                            index,
                            quantity: newQuantity - previousQuantity,
                        },
                    ],
                };
            }

            return {
                ...acc,
                productsToRemove: [
                    ...acc.productsToRemove,
                    {
                        ...product,
                        selectedVariantId: variantId,
                        index,
                        quantity: previousQuantity - newQuantity,
                    },
                ],
            };
        },
        {
            productsToAdd: [] as (ApiProduct & {
                quantity: number;
                index?: number;
                selectedVariantId?: string;
            })[],
            productsToRemove: [] as (ApiProduct & {
                quantity: number;
                selectedVariantId?: string;
            })[],
        },
    );

    if (productsToAdd.length) {
        addProductItems(analyticsOptions)(productsToAdd);
    }

    if (productsToRemove.length) {
        removeProductItems(productsToRemove, analyticsOptions);
    }
};

export interface OrderStatusAnalyticsOptions {
    order_status?: string;
}

export interface RecipeAnalyticsOptions {
    recipe_list_id?: string;
    recipe_list_name?: string;
    recipe_type?: string; // Send only one category. If multiple categories are selected in "Create Your Own Menu," send only "custom" (NOT "familjefavoriter|prisvärt|mer smak").
    recipe_origin?: 'weekly menu' | 'recipes';
}

// custom data sent with every modifyCartRequest, for example additional recipe data
export interface ModifyCartAnalyticsOptions
    extends AnalyticsListOptions,
        RecipeAnalyticsOptions,
        OrderStatusAnalyticsOptions {
    removalActionType?: RemoveFromCartType;
}

const addToCart = (
    _products: {
        productId: string;
        variantId: string | undefined;
        quantity: number;
    }[],
    storeId: string,
    analyticsOptions: ModifyCartAnalyticsOptions | null,
) =>
    modifyCartItems(
        _products.map((item) => ({
            productId: item.productId,
            variantId: item.variantId,
            previousQuantity: 0,
            newQuantity: item.quantity,
        })),
        storeId,
        analyticsOptions,
    );

const removeFromCart = (
    _products: {
        productId: string;
        variantId: string | undefined;
        quantity: number;
    }[],
    storeId: string,
    analyticsOptions: ModifyCartAnalyticsOptions | null,
) => {
    modifyCartItems(
        _products.map((item) => ({
            productId: item.productId,
            variantId: item.variantId,
            previousQuantity: item.quantity,
            newQuantity: 0,
        })),
        storeId,
        analyticsOptions,
    );
};

export const changeCartItems = (
    previousProducts: {
        productId: string;
        variantId: string | undefined;
        quantity: number;
    }[],
    currentProducts: {
        productId: string;
        variantId: string | undefined;
        quantity: number;
    }[],
    storeId: string,
    analyticsOptions: ModifyCartAnalyticsOptions | null,
) => {
    const changedItems = getGaChangedItems(previousProducts, currentProducts);

    modifyCartItems(changedItems, storeId, analyticsOptions);
};

export default {
    changeCartItems: failSilently(changeCartItems),
    modifyCartItems: failSilently(modifyCartItems),
    addToCart: failSilently(addToCart),
    removeFromCart: failSilently(removeFromCart),

    viewCart: failSilently(viewCart),
    addToWishlist: failSilently(addToWishlist),
    replacedItems: {
        addItems: failSilently(replacedItemsAddItems),
        removeItems: failSilently(replacedItemsRemoveItems),
    },
    miniCartRecipeAccordionClick: failSilently(miniCartRecipeAccordionClick),
    cartRecipeSubstituteTriggerClick: failSilently(cartRecipeSubstituteTriggerClick),
};
