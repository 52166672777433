import getDirectPersonalizationSearchAxiosClient from '../../search/api/directPersonalizationAxiosClient';
import type { ApiEventsRequest } from './trackingRequests';

const prepareEventRequestParams = (storeId: string, customerGroups: string[], device: string) => {
    const requestParams = {
        store: storeId,
        groups: customerGroups && !!customerGroups.length ? customerGroups.join(',') : null,
        device,
    };

    return requestParams;
};

const trackingDirectApi = {
    track: async (
        eventRequest: ApiEventsRequest,
        storeId: string,
        customerGroups: string[],
        device: string,
    ) => {
        const client = getDirectPersonalizationSearchAxiosClient(false);
        await client.post('/events', eventRequest, {
            params: prepareEventRequestParams(storeId, customerGroups, device),
        });
    },
};

export default trackingDirectApi;
