/* eslint-disable react/jsx-props-no-spreading */
import { CheckmarkIcon, Warning2Icon } from '@coop/icons';
import classnames from 'classnames';
import * as React from 'react';
import { useRef } from 'react';
import { pick } from 'underscore';

import { Icon } from '../Icon';
import DisabledTooltip from './DisabledTooltip';
import styles from './TextInput.module.scss';
import type { BaseTextInputProps, TextInputProps } from './TextInput.types';

type AllOptionalKeys<T> = { [K in keyof T]-?: undefined extends T[K] ? K : never }[keyof T];
type AllNonOptionalKeys<T> = { [K in keyof T]-?: undefined extends T[K] ? never : K }[keyof T];

type OptionalToMaybeUndefined<T> = { [K in AllOptionalKeys<T>]: T[K] | undefined } & {
    [K in AllNonOptionalKeys<T>]: T[K];
};

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
    ({ type = 'text', ...props }, forwardedRef) => {
        const accessibilityDescriptionId = `accessibilityDescription-${props.id}`;

        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (props.disabledReason) {
                e.preventDefault();
                e.stopPropagation();
                return;
            }

            if (props.onChange) {
                props.onChange(e);
            }
        };

        const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (props.disabledReason && e.key !== 'Tab') {
                e.preventDefault();
                e.stopPropagation();
                return;
            }

            if (props.onKeyDown) {
                props.onKeyDown(e);
            }
        };

        const inputContainerRef = useRef<HTMLDivElement>(null);
        const isValid = !props.disabledReason && props.validationState === 'valid';
        const isInvalid = !props.disabledReason && props.validationState === 'invalid';
        const ariaInvalidStatusText = isInvalid ? 'true' : 'false';

        const customProps: OptionalToMaybeUndefined<BaseTextInputProps> = {
            className: props.className,
            containerClass: props.containerClass,
            disabledReason: props.disabledReason,
            helperText: props.helperText,
            label: props.label,
            type,
            validationState: props.validationState,
            validationText: props.validationText,
        };

        const keysNotFromCustomProps = Object.keys(props).filter(
            (key) => !Object.keys(customProps).includes(key),
        );

        const baseInputProps = pick(props, keysNotFromCustomProps);

        return (
            <div className={customProps.containerClass}>
                <div
                    ref={inputContainerRef}
                    className={classnames(styles.InputContainer, props.containerClass)}
                >
                    <input
                        {...baseInputProps}
                        className={classnames(
                            styles.Input,
                            props.className,
                            props.validationState === 'invalid' && styles['is-invalid'],
                            props.validationState === 'valid' && styles['is-valid'],
                            props.value && styles['has-value'],
                        )}
                        type={type}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        ref={forwardedRef}
                        aria-invalid={ariaInvalidStatusText}
                        aria-disabled={!!props.disabledReason}
                        aria-describedby={accessibilityDescriptionId}
                    />
                    <label htmlFor={props.id}>{props.label}</label>
                    <span className={styles.Icons}>
                        {props.disabledReason && (
                            <DisabledTooltip
                                disabledReason={props.disabledReason}
                                container={inputContainerRef.current}
                            />
                        )}
                        {isValid && (
                            <div
                                className={classnames(
                                    styles.ValidationIcon,
                                    styles['ValidationIcon-valid'],
                                )}
                            >
                                <Icon color="white" icon={CheckmarkIcon} />
                            </div>
                        )}
                        {isInvalid && (
                            <div
                                className={classnames(
                                    styles.ValidationIcon,
                                    styles['ValidationIcon-invalid'],
                                )}
                            >
                                <Icon color="white" icon={Warning2Icon} />
                            </div>
                        )}
                    </span>
                    <span
                        id={accessibilityDescriptionId}
                        className={styles.AccessibilityDescription}
                    >
                        {isInvalid && props.validationText}
                        {props.disabledReason}
                    </span>
                </div>

                <p className={styles.ValidationText}>{isInvalid && props.validationText}</p>
                {props.helperText && <p className={styles.HelperText}>{props.helperText}</p>}
            </div>
        );
    },
);

export default TextInput;
