import { ErrorGroup } from '../../../store/structureDefinitions/errorsState';
import ErrorGroupNotification from './ErrorGroupNotification';

const GlobalErrorNotification = () =>
    ErrorGroupNotification({
        errorGroup: ErrorGroup.Global,
        hideAfterMiliseconds: 2000,
        onClose: undefined,
    });

export default GlobalErrorNotification;
