import { CloseIcon } from '@coop/icons';
import classNames from 'classnames';
import * as React from 'react';

import { ChipIconButton } from '../Chip';
import { Modal, ModalHeader } from '../Modal';
import ModalDescription from '../Modal/ModalDescription';
import { OverlayLoader } from '../OverlayLoader';
import styles from './Confirm.module.scss';

export type BaseConfirmProps = {
    /**
     * Show or hide the confirm dialog
     */
    show: boolean;

    /**
     * The heading of the confirm dialog
     */
    heading: string;

    /**
     * The message of the confirm dialog
     */
    message?: React.ReactNode;

    /**
     * Function to close the confirm dialog
     */
    close: () => void;

    /**
     * Show or hide the loading indicator
     */
    isLoading?: boolean;

    /**
     * Position of the confirm dialog
     */
    position?: 'fixed' | 'absolute';

    /**
     * Disable outside click to close the confirm dialog
     */
    disableOutsideClick?: boolean;

    /**
     * Slot for custom body content,
     */
    bodySlot?: React.ReactNode;

    /**
     * Render trigger for the confirm dialog for the browser to know where should it focus when the dialog is closed
     */
    renderTrigger?: (ref: React.RefObject<HTMLButtonElement>) => React.ReactNode;

    idForDebug?: string;
    afterClose?: () => void;
};

const BaseConfirm = ({
    position = 'fixed',
    ...props
}: React.PropsWithChildren<
    BaseConfirmProps & {
        buttonSlot?: React.ReactNode;
        closeButtonRef?: React.RefObject<HTMLButtonElement>;
    }
>) => {
    return (
        <Modal
            position={position}
            isOpen={props.show}
            close={props.close}
            disableOutsideClick={props.disableOutsideClick}
            additionalClasses={styles.ModalWrapper}
            initialFocusOnDialog={false}
            renderTrigger={props.renderTrigger}
            idForDebug={props.idForDebug}
            afterClose={props.afterClose}
        >
            <section className={classNames(styles.Confirm)}>
                <div className={styles.ConfirmClose}>
                    <ChipIconButton
                        ref={props.closeButtonRef}
                        size={32}
                        onClick={props.close}
                        icon={CloseIcon}
                        label="Stäng dialog"
                        theme="whiteGreen"
                    />
                </div>
                <div className={styles.ConfirmDialog}>
                    <ModalHeader className={styles.ConfirmDialogHeader}>
                        {props.heading}
                    </ModalHeader>

                    {!!props.message && (
                        <ModalDescription className={classNames(styles.ConfirmDialogMessage)}>
                            {props.message}
                        </ModalDescription>
                    )}

                    {!!props.bodySlot && (
                        <div className={classNames(styles.ConfirmDialogBody)}>{props.bodySlot}</div>
                    )}

                    {!!props.buttonSlot && (
                        <div className={classNames(styles.ConfirmDialogFooter)}>
                            {props.isLoading && (
                                <OverlayLoader options={{ isOverlaySection: true }} />
                            )}

                            {props.buttonSlot}
                        </div>
                    )}
                </div>
            </section>
        </Modal>
    );
};

export default BaseConfirm;
