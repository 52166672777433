import * as React from 'react';

import { Button } from '../Button';
import type { BaseConfirmProps } from './BaseConfirm';
import BaseConfirm from './BaseConfirm';
import { useInitialConfirmFocus } from './Confirm.hooks';

interface ConfirmProps extends BaseConfirmProps {
    onConfirm: () => void;
    onCancel?: () => void;
    cancelButtonText?: string;
    /**
     * Text for the confirm button
     */
    confirmButtonText?: string;
}

/**
 * Confirm modal with two buttons, one for confirm and one for cancel.
 * Useful when we want the user to confirm or cancel a specific choice.
 */
const Confirm = (props: ConfirmProps) => {
    const confirmButtonTextFinal = props.confirmButtonText || 'Ja';
    const cancelButtonTextFinal = props.cancelButtonText || 'Avbryt';

    const leastDestructiveActionRef = useInitialConfirmFocus(props.show);

    return (
        <BaseConfirm
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            buttonSlot={
                <>
                    <Button
                        ref={leastDestructiveActionRef}
                        theme="light"
                        onClick={() => {
                            props.onCancel?.();
                            props.close();
                        }}
                    >
                        {cancelButtonTextFinal}
                    </Button>
                    <Button theme="primary" onClick={props.onConfirm}>
                        {confirmButtonTextFinal}
                    </Button>
                </>
            }
        />
    );
};

export default Confirm;
