import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../store/structureDefinitions/cartState';

const selectProfile = (state: RootState) => state.user.profile;
const selectFirstName = createSelector(selectProfile, (state) => state?.firstName);
const selectLastName = createSelector(selectProfile, (state) => state?.lastName);
const selectIsAuthenticated = createSelector(selectProfile, (state) => !!state?.isAuthenticated);
const selectIsMember = createSelector(selectProfile, (state) => !!state?.medmeraId);

const selectFullName = createSelector(selectFirstName, selectLastName, (first, last) => {
    if (!first || !last) {
        return undefined;
    }
    return `${first} ${last}`;
});

const selectInitials = createSelector(selectFirstName, selectLastName, (first, last) => {
    if (!first || !last) {
        return undefined;
    }

    return `${first.charAt(0)}${last.charAt(0)}`;
});

export const userProfileSelectors = {
    profile: selectProfile,
    fullName: selectFullName,
    initials: selectInitials,
    isAuthenticated: selectIsAuthenticated,
    isMember: selectIsMember,
};
