import classNames from 'classnames';
import * as React from 'react';

import RawHtml from '../../../common/components/atoms/RawHtml';
import UserTypeModeSwitch from '../UserTypeModeSwitch';

interface PreHeaderProps {
    preHeaderText?: string;
    showB2BSwitch: boolean;
    fullUserName?: string;
    isAuthenticated: boolean;
    isMember: boolean;
    isCompany: boolean;
}

const PreHeader = (props: PreHeaderProps) => {
    return (
        <div className={classNames('PreHeader', 'PreHeader--gray')}>
            <div>{props.preHeaderText && <RawHtml html={props.preHeaderText} />}</div>
            {(() => {
                if (props.isAuthenticated && props.isCompany)
                    return (
                        <div>
                            <span>Inloggad som företagskund</span>
                        </div>
                    );

                if (props.isAuthenticated)
                    return (
                        <div>
                            <span>Inloggad som:&nbsp;</span>
                            <span className="u-textWeightBold">{props.fullUserName}</span>
                            {!props.isMember && <span>&nbsp;(ej medlem)</span>}
                        </div>
                    );

                if (!props.isAuthenticated && props.showB2BSwitch) {
                    return <UserTypeModeSwitch theme="white" indicatorPosition="top" />;
                }

                return null;
            })()}
        </div>
    );
};

export default PreHeader;
