import type { FC } from 'react';
import * as React from 'react';
import { useCallback } from 'react';

import { useAppSelector } from '../../../common/hooks/useThunkDispatch';
import { storeSelectors } from '../../../common/selectors/storeSelectors';
import { SearchStateProvider } from '../../../search/contexts/searchStateContext';
import { globalSearchAbortable } from '../../../search/hooks/useGlobalSearchState';
import { SearchFromPageType } from '../../models/SearchFromPageType';

interface GlobalSearchStateProviderProps {
    initialQuery: string;
    searchFromPage: SearchFromPageType;
}

const GlobalSearchContext: FC<React.PropsWithChildren<GlobalSearchStateProviderProps>> = ({
    searchFromPage,
    initialQuery,
    children,
}) => {
    const currentProductionUnit = useAppSelector(storeSelectors.currentProductionUnit);

    const globalSearchFunction = useCallback(
        async (_abortSignal: AbortSignal, _query: string, personalizeSearch: boolean) =>
            globalSearchAbortable(
                _query,
                currentProductionUnit,
                {
                    articles: 4,
                    products: 4,
                    stores: 4,
                    recipes: searchFromPage === SearchFromPageType.Recipes ? 10 : 5,
                    pointshop: 6,
                },
                _abortSignal,
                true,
                personalizeSearch,
            ),
        [currentProductionUnit, searchFromPage],
    );

    return (
        <SearchStateProvider
            initialQuery={initialQuery}
            searchFunction={globalSearchFunction}
            searchFromPage={searchFromPage}
            minimumQueryLength={2}
        >
            {children}
        </SearchStateProvider>
    );
};

export default GlobalSearchContext;
