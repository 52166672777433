import './SearchOverlay.module.less';

import { Search } from '@coop/components';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import * as React from 'react';
import { Suspense } from 'react';

import { BrowserSize } from '../../../../utility/browser';
import { Modal } from '../../../common/components/atoms/Modal';
import { useFromBrowserMediaQuery } from '../../../common/components/atoms/ResponsiveBreakpoints';
import { useSearchStateContext } from '../../../search/contexts/searchStateContext';

interface SearchOverlayProps {
    onEnterPress: () => void;
    children: ReactNode;
    onOutsideClick: () => void;
    onResetClick: () => void;
}

const SearchOverlay = React.forwardRef<
    HTMLInputElement,
    React.PropsWithChildren<SearchOverlayProps>
>((props, inputRef) => {
    const { query, isOpen, reset, setQuery, show } = useSearchStateContext();

    const isFromLarge = useFromBrowserMediaQuery(BrowserSize.lg);
    return (
        <Modal
            additionalClasses="SearchOverlay"
            isOpen={isOpen}
            close={reset}
            initialFocusOnDialog={false}
            animation="fade"
            afterOutsideClick={props.onOutsideClick}
        >
            <div className={classNames('SearchBarWrapper', show && 'SearchBarWrapper--hasResults')}>
                <Search
                    ref={inputRef}
                    inputText={query}
                    setInputText={setQuery}
                    useDropdown={false}
                    size={isFromLarge ? 'large' : 'medium'}
                    inputPlaceholder="Sök recept, varor, butiker och artiklar"
                    onEnterPress={props.onEnterPress}
                    onEscapePress={reset}
                    onReset={() => {
                        reset();
                        props.onResetClick();
                    }}
                    focusOnMount
                    theme="shadowed"
                />
            </div>

            <Suspense fallback={null}>
                {show && <div className="SearchResults">{props.children}</div>}
            </Suspense>
        </Modal>
    );
});
export default SearchOverlay;
