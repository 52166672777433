import { appConfig } from '../../../appConfig';

export const ensureTrailingSlash = (url: string) => {
    return url.replace(/(\/*)?$/, '/');
};

export const ensureLeadingSlash = (url: string) => {
    return url.replace(/^(\/*)?/, '/');
};

const RETURN_URL = 'returnUrl';

export const getCreateAccountUrl = () => {
    const { loginUrl } = appConfig.coopSettings;
    const queryString = new URLSearchParams();
    queryString.set('loginType', 'createLoginAccount');
    return `${loginUrl}?${queryString.toString()}`;
};

export const getLoginUrlWithReturnUrl = (stayOnPage?: boolean) => {
    const { loginUrl } = appConfig.coopSettings;
    const queryString = new URLSearchParams();
    queryString.set(RETURN_URL, window.location.pathname);

    if (stayOnPage) {
        queryString.set(RETURN_URL, window.location.pathname);
    } else {
        queryString.set(RETURN_URL, appConfig.coopSettings.myCoop.myCoopUrl);
    }

    return `${loginUrl}?${queryString.toString()}`;
};

export const addQueryParamsToUrl = (
    url: string,
    paramValues: Record<string, string | undefined>,
) => {
    const [path, currQueryString = ''] = url.split('?');

    const removedPotentialLastSlashFromPath =
        path.slice(-1) === '/' ? path.slice(0, path.length - 1) : path;

    const urlSearchParams = Object.entries(paramValues).reduce((params, [key, value]) => {
        if (value && value?.length > 0) {
            params.set(key, value);
        }
        return params;
    }, new URLSearchParams(currQueryString));

    const correctEnding = urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : '/';

    return `${removedPotentialLastSlashFromPath}${correctEnding}`;
};
