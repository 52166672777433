import { useLayoutEffect, useRef } from 'react';

export const useInitialConfirmFocus = (isOpen: boolean) => {
    const ref = useRef<HTMLButtonElement>(null);

    useLayoutEffect(() => {
        if (isOpen && ref.current) {
            ref.current.focus();
        }
    }, [isOpen]);

    return ref;
};
