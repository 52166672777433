import { Icon } from '@coop/components';
import { useMultipleOutsideClickCallbacked } from '@coop/hooks';
import { Bag1Icon, CloseIcon, HeartIcon, HomeIcon, PaperIcon } from '@coop/icons';
import type { FC } from 'react';
import * as React from 'react';
import { useCallback, useMemo, useRef } from 'react';

import { appConfig } from '../../../../appConfig';
import { DropdownAnchorList } from '../../../common/components/molecules/Dropdown';
import useModal from '../../../common/hooks/useModal';
import { DropdownType } from '../../../common/store/structureDefinitions/modalState';
import { ga4NavigationTracking } from '../../../common/tracking/ga4/navigation';
import HeaderIconButton from '../HeaderIconButton/HeaderIconButton';

type MyFavoritesDrodpownLinkItem = {
    name: string;
    url: string;
    icon?: (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => JSX.Element;
};

const MyFavoritesDropdownTrigger: FC<React.PropsWithChildren<unknown>> = () => {
    const { isOpen, close, open } = useModal(DropdownType.MyFavorites);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const dropdownOptions: MyFavoritesDrodpownLinkItem[] = [
        {
            name: 'Sparade varukorgar',
            url: appConfig.coopSettings.myCoop.savedCartListUrl,
            icon: Bag1Icon,
        },
        {
            name: 'Sparade inköpslistor',
            url: appConfig.coopSettings.myCoop.shoppingListPageUrl,
            icon: PaperIcon,
        },
        {
            name: 'Sparade recept',
            url: appConfig.coopSettings.myCoop.savedRecipesPageUrl,
            icon: HeartIcon,
        },
        {
            name: 'Favoritbutiker',
            url: appConfig.coopSettings.stores.mapStorePageUrl,
            icon: HomeIcon,
        },
    ];

    const handleTriggerClick = () => {
        if (isOpen) {
            close();
            ga4NavigationTracking.savedItems.close();
        } else {
            open();
            ga4NavigationTracking.savedItems.open();
        }
    };

    const handleClose = useCallback(() => {
        close();
        ga4NavigationTracking.savedItems.close();
    }, [close]);

    const handleListItemClick = (
        e: React.MouseEvent<HTMLAnchorElement>,
        item: MyFavoritesDrodpownLinkItem,
    ) => {
        ga4NavigationTracking.savedItems.click(item.name, e.currentTarget.href);
    };

    const refs = useMemo(
        () => [buttonRef, dropdownRef] as React.MutableRefObject<HTMLElement>[],
        [buttonRef, dropdownRef],
    );

    useMultipleOutsideClickCallbacked(refs, handleClose, isOpen, {
        conditionStrategy: 'meetAll',
    });

    return (
        <div className="u-posRelative">
            <HeaderIconButton
                ref={buttonRef}
                icon={isOpen ? CloseIcon : HeartIcon}
                label="Mina favoriter"
                onClick={handleTriggerClick}
                theme="greyBorder"
            />
            <DropdownAnchorList
                innerRef={dropdownRef}
                isExpanded={isOpen}
                options={dropdownOptions}
                onClick={handleListItemClick}
                renderOption={(item) => (
                    <>
                        <div className="DropdownList-item SvgWrapper">
                            {item.icon && <Icon icon={item.icon} width={16} height={16} />}
                        </div>
                        {item.name}
                    </>
                )}
            />
        </div>
    );
};

export default MyFavoritesDropdownTrigger;
