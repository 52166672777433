import { appConfig } from '../../../../appConfig';
import { Cookies } from '../../../../utility/cookies';
import { BUSINESS_TOGGLE_COOKIE } from '../../../common/persistance/cookie/cookieKeys';
import { getAnonymousCustomerGroups } from '../../../common/utils/customerGroupsUtils';

export const b2bModeTrigger = () => {
    const isB2BUser = getAnonymousCustomerGroups().includes('CUSTOMER_COMPANY');
    const isB2CUser = !isB2BUser;

    const switchToB2B = () => {
        if (!appConfig.coopUserSettings.isAuthenticated) {
            Cookies.create(BUSINESS_TOGGLE_COOKIE, 'true');

            // always redirect to ecommerce handla page
            window.location.href = appConfig.coopSettings.ecommerce.url;
        }
    };

    const switchToB2C = () => {
        if (!appConfig.coopUserSettings.isAuthenticated) {
            Cookies.eraseCookie(BUSINESS_TOGGLE_COOKIE);

            // always redirect to ecommerce handla page
            window.location.href = appConfig.coopSettings.ecommerce.url;
        }
    };

    const toggle = () => {
        if (!appConfig.coopUserSettings.isAuthenticated) {
            if (isB2BUser) {
                switchToB2C();
            } else {
                switchToB2B();
            }
        }
    };

    return {
        isB2CUser,
        isB2BUser,
        switchToB2B,
        switchToB2C,
        toggle,
    };
};
