import type { ApiFacetOperator, ApiLoopSort, LoopSearchFilterType } from '../api/searchRequests';

export type SearchSortOrderType = 'asc' | 'desc';
export type SearchSortType = 'relevance' | 'attribute' | 'id' | 'type' | 'popularity';

export interface SearchResultFacet {
    name: string;
    count?: number;
    isSelected: boolean;
}

export interface LegacySearchResults extends SearchResults<ApiProduct> {
    environmentalFacets?: SearchResultFacet[];
}

export interface LoopSearchProductResults extends SearchResults<ApiProduct>, SearchResultFacets {
    relatedResult?: BaseSearchResults<ApiProduct>;
    queryUsed: string;
}

export interface LoopNoQueryProductResults
    extends BaseSearchResults<ApiProduct>,
        SearchResultFacets {
    relatedResult?: BaseSearchResults<ApiProduct>;
}

interface SearchResultFacets {
    environmentalFacets?: SearchResultFacet[];
    brandFacets?: SearchResultFacet[];
    categoryIdsFacets?: SearchResultFacet[];
    topCategoryFacets?: SearchResultFacet[];
}

export interface BaseSearchResults<T> {
    items: T[];
    totalResults: number;
}

export interface RecipeCategoryFacet {
    categories: RecipeCategoryFacet[] | null;
    count: number;
    item: string;
    selected: boolean;
}
export interface SearchRecipeResult {
    count: number;
    facets: { name: string; items: RecipeCategoryFacet[] }[];
    items: SearchRecipe[];
    queryUsed: string;
}

export interface SearchResults<T> extends BaseSearchResults<T> {
    query: string;
    makeSense?: boolean;
    spellingSuggestions: string[];
}

export interface GlobalSearchResults {
    searchTerm: string;
    queryUsed: string;
    makeSense: boolean;
    spellingSuggestions: string[];
    productResults?: BaseSearchResults<ApiProduct>;
    articleResults?: BaseSearchResults<SearchArticle>;
    storeResults?: BaseSearchResults<SearchStore>;
    pointshopResults?: BaseSearchResults<SearchPointshop>;
    recipeResults?: BaseSearchResults<LightSearchRecipe>;
    recipeCategoryResults?: BaseSearchResults<SearchRecipeCategory>;
}

export interface SearchRequestFacet {
    type: SearchFacetType;
    selected: string[];
    operator?: ApiFacetOperator;
}

export interface SearchRequestFilter {
    type: 'attribute' | 'type' | 'id';
    attributeName?: string;
    value: string | number | boolean;
    comparisonMode:
        | 'equals'
        | 'greaterThan'
        | 'greaterThanOrEquals'
        | 'lessThan'
        | 'lessThanOrEquals'
        | 'contains';
}

export interface SearchRequestAndFilter {
    and: LoopSearchFilterType[];
    type?: 'And';
}
export interface SearchRequestOrFilter {
    or: LoopSearchFilterType[];
    type?: 'Or';
}

export enum SearchFacetType {
    RecipeCategories = 'categories',
    Brand = 'brand',
    Loyaltybrand = 'loyaltybrand',
    EnvironmentalLabels = 'environmentalLabels',
    CategoryIds = 'categoryIds',
    LoyaltyCategory = 'loyaltyCategory',
    TopCategory = 'topCategory',
}

export interface BaseSearchRequest {
    page: number;
    resultsPerPage: number;
    sortBy: string[];
    facets: SearchRequestFacet[];
}
export interface PointshopSearchRequest {
    page: number;
    resultsPerPage: number;
    sortBy: string[];
    facets: SearchRequestFacet[];
    filter?: SearchRequestAndFilter | SearchRequestOrFilter;
}
export interface PointshopSearchAndRequest {
    page: number;
    resultsPerPage: number;
    sortBy: string[];
    facets: SearchRequestFacet[];
    filter?: SearchRequestAndFilter;
}
export interface RecipeSearchRequest extends Omit<BaseSearchRequest, 'sortBy'> {
    searchQuery: string;
    sortBy?: ApiLoopSort<keyof ApiLoopSearchRecipe>[];
}

export interface SearchRequest extends BaseSearchRequest {
    searchTerm: string;
}

export interface ProductSearchRequest extends SearchRequest {
    storeId: string;
}

export interface ProductOfferSearchRequest extends BaseSearchRequest {
    storeId: string;
}

interface SearchAttribute {
    name: string;
    value: string;
}

export interface SearchRequestAlias {
    /** Specify an alias for this attribute name. */
    name: string;
    /** Specify an alias for this attribute value. */
    value: string;
    /** Specify a more detailed description for this attribute name-value pair. Will be shown in the Portal. */
    details: string;
}

export interface SearchByAttributeRequest extends BaseSearchRequest {
    attribute: SearchAttribute;
    storeId: string;
    customData?: CustomSearchRequestData;
    /** Provides human-readable labels in the Portal. */
    requestAlias?: SearchRequestAlias;
}

export interface CustomSearchRequestData {
    getEntitiesByAttributeABTest: boolean;
}

export interface GlobalSearchRequest {
    searchTerm: string;
    storeId: string;
    productSearchRequest?: BaseSearchRequest;
    articleSearchRequest?: BaseSearchRequest;
    storeSearchRequest?: BaseSearchRequest;
    pointshopSearchRequest?: PointshopSearchRequest;
    recipeSearchRequest?: BaseSearchRequest;
    recipeCategoriesSearchRequest?: BaseSearchRequest;
}

export type SortOrderType =
    | 'popular'
    | 'product_name+asc'
    | 'product_effectiveprice_cents+asc'
    | 'product_effectiveprice_cents+desc'
    | 'product_effectiveprice_with_promo+asc'
    | 'product_effectiveprice_with_promo+desc';

export interface TrackPurchaseRequest {
    products: TrackPurchaseProduct[];
    orderId: string;
}

export interface TrackPurchaseProduct {
    productId: string;
    quantity: number;
    revenue?: number;
}

export interface AutocompleteResponse {
    query: string;
    hints: string[];
}

export enum SearchType {
    Ai = 'Ai',
}

export interface AISearchRequest {
    query: string;
    page: number;
    pageSize: number;
}

// #region Store
export interface SearchStore {
    id: string;
    zip: string;
    address: string;
    city: string;
    latitude: number;
    longitude: number;
    imageUrl: string;
    services: string[];
    url: string;
    hasDR: boolean;
    ledgerAccountNumber: number;
    name: string;
    openingHours: StoreOpeningHours[];
}

export interface StoreOpeningHours {
    date?: Date;
    isSpecialDate: boolean;
    openFrom: string;
    openHoursText: string;
    week: number;
    openTo: string;
    sortOrder: number;
    text: string;
    type: number;
}
export interface SearchStoreResults extends SearchResults<SearchStore> {}
// #endregion

// #region Article
export interface SearchArticle {
    id: string;
    published: Date;
    name: string;
    headline: string;
    preamble: string;
    body: string;
    url: string;
    imageUrl: string;
}

export interface SearchArticleResults extends SearchResults<SearchArticle> {}
// #endregion

// #region Pointshop

export interface SearchPointshop {
    id: string;
    category: string[];
    url: string;
    name: string;
    imageUrl: string;
    headline: string;
    preamble: string;
    body: string;
    instruction: string;
    brand: string;
    pointPrice: number;
    fulfillmentProcess: number[];
    validityStartDate: string;
    validityEndDate: string;
    condition: string;
    type: 'PartneroffersEntity' | 'PointshopEntity';
    published: string;
    contentType: string;
}
export interface SearchPointshopResults extends SearchResults<SearchPointshop> {}

// #endregion

// #region Recipes

export interface LightSearchRecipe {
    id: string; // this is in fact a recipe's EXTERNAL ID
    name: string;
    averageRating: number;
    numberOfVotes: number;
    preamble: string;
    relativeUrl?: string;
    url: string;
    imageUrl: string;
    cookingTime: string;
    climateImpactKg: number | null;
    isFoodBox: boolean;
}

export interface SearchRecipe extends LightSearchRecipe {
    cookingInstructions: string;
    ovenTemperature: string;
    categories: string[];
    ingredients: string[];
    yieldUnit: string;
    yieldAlternative: string;
    alternativeTitle: string;
    totalTime: string;
    preparationTime: string;
    tips: string;
    keywords: string;
    servingTips: string;
    yieldValue?: number;
    publicationDate?: Date;
    energyValueKj?: number;
    energyValueKcal?: number;
    proteinPercent?: number;
    proteinGram?: number;
    carbPercent?: number;
    carbGram?: number;
    fatPercent?: number;
    fatGram?: number;
    saturatedFatGram?: number;
    saltGram?: number;
}

// #endregion

export interface SearchRecipeCategory {
    name: string;
    preamble: string;
    url: string;
    imageUrl: string;
}
