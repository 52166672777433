import classNames from 'classnames';
import type { PropsWithChildren, ReactNode } from 'react';
import * as React from 'react';

import { useAppSelector } from '../../../common/hooks/useThunkDispatch';
import { getMainContainerClassByLayout } from '../../utils/layoutUtils';

interface SearchInContentProps {
    leftSlot?: ReactNode;
    rightSlot?: ReactNode;
    searchSlot: ReactNode;
    bottomRow?: ReactNode;
}

const SearchInContent = React.forwardRef<HTMLDivElement, PropsWithChildren<SearchInContentProps>>(
    (props, ref) => {
        const layoutSize = useAppSelector((state) => state.ui.shared.layoutSize);
        const hasSideBar = useAppSelector((state) => state.ui.shared.hasSideBar);

        return (
            <div
                ref={ref}
                className={classNames(
                    'SearchInContent',
                    getMainContainerClassByLayout(layoutSize, hasSideBar),
                )}
                data-test="searchHeader"
            >
                {(props.leftSlot || props.searchSlot || props.rightSlot) && (
                    <div className="SearchInContent--row u-noPrint">
                        {props.leftSlot && (
                            <div className="SearchInContent--cell SearchInContent--cellLeft">
                                {props.leftSlot}
                            </div>
                        )}

                        {props.searchSlot && (
                            <div className="SearchInContent--cell SearchInContent--cellSearch">
                                {props.searchSlot}
                            </div>
                        )}

                        {props.rightSlot && (
                            <div className="SearchInContent--cell SearchInContent--cellRight">
                                {props.rightSlot}
                            </div>
                        )}
                    </div>
                )}
                {props.bottomRow && <div className="SearchInContent--row">{props.bottomRow}</div>}
            </div>
        );
    },
);

export default SearchInContent;
