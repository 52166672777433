const inMemoryPromises: Record<string, Promise<unknown> | undefined> = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncFunction<TReturnType> = (uniqueName: string, ...args: any[]) => Promise<TReturnType>;

const callOnce =
    <TReturnType>(promise: AsyncFunction<TReturnType>) =>
    (
        uniqueName: string,
        ...args: ExtractParamsButFirst<Parameters<AsyncFunction<TReturnType>>>
    ) => {
        if (inMemoryPromises[uniqueName]) {
            return <ReturnType<AsyncFunction<TReturnType>>>inMemoryPromises[uniqueName];
        }

        inMemoryPromises[uniqueName] = promise(uniqueName, ...args);
        return <ReturnType<AsyncFunction<TReturnType>>>inMemoryPromises[uniqueName];
    };

export const callOnceClear = (uniqueName: string) => {
    delete inMemoryPromises[uniqueName];
};

export default callOnce;
