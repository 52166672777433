import { Confirm } from '@coop/components';
import type { FC } from 'react';
import * as React from 'react';

import { useGroupError } from '../../../hooks/useGroupError';
import { ErrorGroup } from '../../../store/structureDefinitions/errorsState';

// We can't recover from it, i.e. checkout unmounts. We need to reload a page

const CriticalErrorNotification: FC<React.PropsWithChildren<unknown>> = () => {
    const { firstError, clearGroupErrors } = useGroupError(ErrorGroup.Critical);

    return (
        // This should be some kind of red error notification with a reload button, TBD
        <Confirm
            heading="Någonting gick snett."
            message="Vänligen ladda om sidan eller försök igen"
            show={!!firstError}
            onConfirm={() => {
                clearGroupErrors();
                window.location.reload();
            }}
            close={clearGroupErrors}
            confirmButtonText="Jag förstår"
        />
    );
};

export default CriticalErrorNotification;
