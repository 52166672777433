import {
    trackAddToCartDY,
    trackAddToCartListDY,
    trackFilterItemsDY,
    trackLoginDY,
    trackPurchaseDY,
    trackRemoveFromCartDY,
    trackRemoveFromCartListDY,
    trackSearchKeywordDY,
    trackSignUpDY,
    trackSortItemsDY,
    trackSyncCartDY,
} from '../../../../utility/dynamicYield';
import trackingFlow from '../../flow/trackingFlow';

export const enum ProductDataProvider {
    Loop54,
}

const trackProductClick = () => (identifier: string, slotId: string, storeId: string) => {
    trackingFlow.productClick({ productId: identifier, slotId }, storeId);
};

const trackRecipeClick = () => (identifier: string, storeId: string) => {
    trackingFlow.recipeClick({ recipeId: identifier }, storeId);
};

const trackAddToCart = () => (productData: ProductTrackingData, storeId: string) => {
    trackingFlow.addToCart(productData.identifier, storeId);
    trackAddToCartDY(productData);
};

const trackPurchase = () => (purchaseData: PurchaseTrackingData, storeId: string) => {
    trackingFlow.purchase(
        {
            orderId: purchaseData.transactionId,
            products: purchaseData.cartItems.map((product) => ({
                productId: product.identifier,
                quantity: product.quantity,
            })),
        },
        storeId,
    );

    trackPurchaseDY(purchaseData);
};

const trackProductFilter = () => (data: ProductFilterTrackingData) => {
    trackFilterItemsDY(data);
};

const trackProductSort = () => (data: ProductSortTrackingData) => {
    trackSortItemsDY(data);
};

const trackSlotClickDY = (productId: string, slotId: string | undefined, storeId: string) => {
    if (slotId) {
        trackingFlow.slotClick({ productId, slotId }, storeId);
    }
};

const trackAddToCartList = () => {
    return trackAddToCartListDY;
};

const trackRemoveFromCart = () => {
    return trackRemoveFromCartDY;
};

const trackRemoveFromCartList = () => {
    return trackRemoveFromCartListDY;
};

const trackSlotClick = () => {
    return trackSlotClickDY;
};

const trackCartItemQuantityUpdate = () => {
    return trackAddToCartDY;
};

const trackSearch = () => {
    return trackSearchKeywordDY;
};

const trackUserLogin = () => {
    return trackLoginDY;
};

const trackUserRegister = () => {
    return trackSignUpDY;
};

const trackFetchCart = () => {
    return trackSyncCartDY;
};

export const personalizationTracking = {
    trackAddToCart,
    trackProductClick,
    trackSlotClick,
    trackRemoveFromCart,
    trackCartItemQuantityUpdate,
    trackPurchase,
    trackProductFilter,
    trackProductSort,
    trackSearch,
    trackUserLogin,
    trackUserRegister,
    trackFetchCart,
    trackAddToCartList,
    trackRemoveFromCartList,
    trackRecipeClick,
};
