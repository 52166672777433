import { SeverityLevel } from '@microsoft/applicationinsights-web';
import type { ErrorInfo } from 'react';
import * as React from 'react';

import { appInsights } from '../../../../appInsights';
import { createDefaultException } from '../../exceptions/exceptionUtils';
import { useGroupError } from '../../hooks/useGroupError';
import { ErrorGroup } from '../../store/structureDefinitions/errorsState';

interface ErrorGroupBoundaryProps extends ErrorBoundryProps {
    errorGroup: ErrorGroup;
}

interface ErrorBoundryProps {
    children: React.ReactNode;
    useNotificationOnError?: boolean;
    errorCallback?: () => void;
    errorSeverityLevel?: SeverityLevel;
    errorFallbackUI?: React.FC<React.PropsWithChildren<{ resetError: () => void }>>;
}

// Need to be a function component to use Hooks
const ErrorGroupBoundary = (props: ErrorGroupBoundaryProps) => {
    const { addGroupError } = useGroupError(props.errorGroup);

    const onError = (err: string) => {
        if (props.useNotificationOnError) {
            addGroupError(createDefaultException(err));
        }

        if (props.errorCallback) {
            props.errorCallback();
        }
    };

    return (
        <ErrorBoundaryClass
            onError={onError}
            errorFallbackUI={props.errorFallbackUI}
            errorSeverityLevel={props.errorSeverityLevel}
        >
            {props.children}
        </ErrorBoundaryClass>
    );
};

interface ErrorBoundaryClassProps extends ErrorBoundryProps {
    onError: (err: string) => void;
    errorFallbackUI?: React.FC<React.PropsWithChildren<{ resetError: () => void }>>;
}

// Needs to be a class component to catch error with componentDidCatch
export class ErrorBoundaryClass extends React.Component<
    ErrorBoundaryClassProps,
    { hasError: boolean }
> {
    constructor(props: ErrorBoundaryClassProps) {
        super(props);
        this.state = {
            hasError: false,
        };

        this.resetError = this.resetError.bind(this);
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        appInsights?.trackException({
            exception: {
                ...error,
                message: `ErrorBoundry: ${error?.message}`,
            },
            severityLevel: this.props?.errorSeverityLevel,
            properties: { ...errorInfo },
        });

        this.props.onError('Ett fel inträffade, försök igen om en stund.');
    }

    resetError() {
        this.setState({ hasError: false });
    }

    render() {
        if (this.state.hasError) {
            if (this.props.errorFallbackUI) {
                return React.createElement(this.props.errorFallbackUI, {
                    resetError: this.resetError,
                });
            }
            return null;
        }
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{this.props.children}</>;
    }
}

// Critical - unexpected error, page is broken, React destroyed components
// Page needs to be reloaded
export const CriticalErrorBoundry = (props: ErrorBoundryProps) =>
    ErrorGroupBoundary({
        errorGroup: ErrorGroup.Critical,
        errorSeverityLevel: SeverityLevel.Critical,
        ...props,
    });

CriticalErrorBoundry.defaultProps = {
    useNotificationOnError: true,
};

// Global - there was an expected error i.e. API call
// if that happens, the global notification will show up
export const GlobalErrorBoundry = (props: ErrorBoundryProps) =>
    ErrorGroupBoundary({
        errorGroup: ErrorGroup.Global,
        errorSeverityLevel: SeverityLevel.Error,
        ...props,
    });

GlobalErrorBoundry.defaultProps = {
    useNotificationOnError: true,
};
