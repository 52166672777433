import { OverlayLoader } from '@coop/components';
import type { FC } from 'react';
import * as React from 'react';
import { Suspense } from 'react';

import { FlyinNavigation } from '../../../common/components/atoms/FlyInNavigation';
import { FlyIn } from '../../../common/components/atoms/Modal';
import useModal from '../../../common/hooks/useModal';
import { FlyInType } from '../../../common/store/structureDefinitions/modalState';

const MyCoopProfileMenu = React.lazy(() => import('./MyCoopProfileMenu'));

const MyCoopProfileFlyIn: FC<React.PropsWithChildren<unknown>> = () => {
    const { isOpen, close } = useModal(FlyInType.MyProfileMenu);

    return (
        <FlyIn isOpen={isOpen} close={close} sizePx={375}>
            <FlyinNavigation onClose={close}>
                <Suspense fallback={<OverlayLoader options={{ isOverlaySection: true }} />}>
                    <MyCoopProfileMenu />
                </Suspense>
            </FlyinNavigation>
        </FlyIn>
    );
};

export default MyCoopProfileFlyIn;
