/* eslint-disable react/jsx-props-no-spreading */
import { IconButton } from '@coop/components';
import * as React from 'react';

import styles from './HeaderIconButton.module.less';

const HeaderIconButton = React.forwardRef<
    HTMLButtonElement,
    Omit<React.ComponentProps<typeof IconButton>, 'theme'> & {
        theme: 'greyBorder' | 'grey';
    }
>((props, ref) => {
    return (
        <IconButton
            ref={ref}
            {...props}
            theme="whiteOutline"
            className={styles[`Button--${props.theme}`]}
        >
            {props.children}
        </IconButton>
    );
});

export default HeaderIconButton;
