import classNames from 'classnames';
import * as React from 'react';

const MainPageLayout = (
    props: React.PropsWithChildren<{
        sideMenuSlot?: React.ReactNode;
        className?: string;
        childrenWrapperClassName?: string;
    }>,
) => {
    return (
        <div
            className={classNames(
                'Main-container Main-container--padding',
                !!props.sideMenuSlot && 'Main-container--full',
                'u-marginBxlg',
                props.className,
            )}
        >
            <div className="Grid Grid--gutterHxlg u-flexNoWrap Grid--dynamicWidth">
                {!!props.sideMenuSlot && (
                    <div className="Grid-cell u-hidden u-lg-block Grid-cell--fixedWidth">
                        {props.sideMenuSlot}
                    </div>
                )}

                <div className="Grid-cell Grid-cell--grownWidth u-block">
                    <div className={classNames('u-posRelative', props.childrenWrapperClassName)}>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainPageLayout;
