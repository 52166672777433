import type { FC } from 'react';
import * as React from 'react';
import type { AsyncStateStatus } from 'react-async-hook';

import type { SearchFromPageType } from '../../base/models/SearchFromPageType';
import type { ApiException } from '../../common/exceptions/exceptionDefinitions';
import useDynamicYieldAbTest from '../../common/hooks/useDynamicYieldAbTest';
import { useSearchDropdownState } from '../../common/hooks/useSearchDropdownState';
import type { SearchResultType } from '../models/SearchResultType';
import { mapSearchFromPageToExpectedResultType } from '../utils/searchResultUtils';

interface SearchDropdownState<TResult> {
    result?: TResult;
    isOpen: boolean;
    error?: ApiException;
    show: boolean;
    query?: string;
    status: AsyncStateStatus;
    searchFromPage?: SearchFromPageType;
    expectedSearchResultType?: SearchResultType;
    isLoading: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SearchDropdownStateContext = React.createContext<SearchDropdownState<any>>({
    isOpen: false,
    result: undefined,
    error: undefined,
    show: false,
    query: undefined,
    searchFromPage: undefined,
    expectedSearchResultType: undefined,
    status: 'not-requested',
    isLoading: false,
});

type SearchDropdownDispatch = Pick<
    ReturnType<typeof useSearchDropdownState>,
    'setQuery' | 'open' | 'close' | 'reset'
>;

const SearchDropdownDispatchContext = React.createContext<SearchDropdownDispatch>({
    setQuery: () => {
        throw new Error('SearchDropdownDispatchContext not yet initialized');
    },
    open: () => {
        throw new Error('SearchDropdownDispatchContext not yet initialized');
    },
    close: () => {
        throw new Error('SearchDropdownDispatchContext not yet initialized');
    },
    reset: () => {
        throw new Error('SearchDropdownDispatchContext not yet initialized');
    },
});

interface SearchStateProviderProps {
    initialQuery: string | null;
    searchFromPage?: SearchFromPageType;
    searchFunction: (
        signal: AbortSignal,
        query: string,
        personalizeSearch: boolean,
    ) => Promise<unknown>;
    minimumQueryLength: number;
}
export const SearchStateProvider: FC<React.PropsWithChildren<SearchStateProviderProps>> = ({
    children,
    searchFunction,
    searchFromPage,
    initialQuery,
    minimumQueryLength,
}) => {
    const {
        result,
        isOpen,
        reset,
        open,
        close,
        error,
        show,
        query,
        setQuery,
        status,
        isLoading,
        setPersonalizeSearch,
    } = useSearchDropdownState(initialQuery, searchFunction, minimumQueryLength);
    const { abTestResponse, isLoadingAbTest } = useDynamicYieldAbTest(
        'product-search-personalization-sort-abtest',
        'product-search-personalization-sort-abtest',
    );
    React.useEffect(() => {
        if (abTestResponse?.isAbTest) {
            setPersonalizeSearch(true);
        }
    }, [isLoadingAbTest, abTestResponse?.isAbTest, setPersonalizeSearch]);
    const expectedSearchResultType = mapSearchFromPageToExpectedResultType(searchFromPage);

    return React.createElement(
        SearchDropdownDispatchContext.Provider,
        { value: { reset, open, close, setQuery } },
        React.createElement(
            SearchDropdownStateContext.Provider,
            {
                value: {
                    result,
                    isOpen,
                    error,
                    show,
                    query,
                    searchFromPage,
                    status,
                    isLoading,
                    expectedSearchResultType,
                },
            },
            children,
        ),
    );
};

export const useSearchStateContext = <TResult = unknown>() => {
    const state = React.useContext<SearchDropdownState<TResult>>(SearchDropdownStateContext);
    const dispatch = React.useContext(SearchDropdownDispatchContext);

    if (state === undefined || dispatch === undefined) {
        throw new Error('useSearchStateContext must be used within a SearchStateProvider');
    }
    return { ...state, ...dispatch };
};
