import { useCallback, useState } from 'react';

import { DropdownType } from '../store/structureDefinitions/modalState';
import useAsyncAbortableDispatcher from './useAsyncAbortableDispatcher';
import useModal from './useModal';

const useAbortableDebouncedSearch = <TResult>(
    inputText: string,
    searchFunction: (
        signal: AbortSignal,
        query: string,
        personalizeSearch: boolean,
    ) => Promise<TResult>,
    minimumQueryLength: number,
    personalizeSearch: boolean,
) => {
    const search = async (abortSignal: AbortSignal, text: string, personalize: boolean) => {
        // If the input is empty, return nothing immediately (without the debouncing delay!)
        if (text.length < minimumQueryLength) {
            return undefined;
        }

        return searchFunction(abortSignal, text, personalize);
    };

    const { status, error, result, isLoading } = useAsyncAbortableDispatcher(
        search,
        [inputText, personalizeSearch],
        {
            executeOnMount: false,
            keepPreviousResult: true,
            debounced: true,
            debounceTime: 300,
        },
    );

    const hasResponse = !!(status === 'success' && !!result) || !!(status === 'error' && error);

    return {
        hasResponse,
        result,
        error,
        isLoading,
        status,
    };
};

export const useSearchDropdownState = <TResult>(
    initialInputText: string | null,
    searchFunction: (
        signal: AbortSignal,
        query: string,
        personalizeSearch: boolean,
    ) => Promise<TResult>,
    minimumQueryLength: number,
) => {
    const { open, close, isOpen } = useModal(DropdownType.Search);

    const [query, setQuery] = useState(initialInputText || '');
    const [personalizeSearch, setPersonalizeSearch] = useState(false);

    const { hasResponse, result, error, status, isLoading } = useAbortableDebouncedSearch(
        query,
        searchFunction,
        minimumQueryLength,
        personalizeSearch,
    );
    const reset = useCallback(() => {
        close();
        setQuery('');
    }, [close]);

    return {
        result,
        isOpen,
        reset,
        open,
        close,
        error,
        show: hasResponse,
        query,
        setQuery,
        status,
        isLoading,
        setPersonalizeSearch,
    };
};
