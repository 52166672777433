import classNames from 'classnames';
import type { FC } from 'react';
import * as React from 'react';

import styles from './UserTypeModeSwitch.module.less';
import { b2bModeTrigger } from './UserTypeModeSwitch.utils';

interface UserTypeModeSwitchProps {
    theme: 'white' | 'green';
    indicatorPosition: 'bottom' | 'top';
}

/**
 * Switch for B2B / B2C site modes
 */
const UserTypeModeSwitch: FC<React.PropsWithChildren<UserTypeModeSwitchProps>> = (props) => {
    const { isB2BUser, isB2CUser, switchToB2B, switchToB2C } = b2bModeTrigger();

    const indicatorPositionClass =
        props.indicatorPosition === 'top'
            ? styles['UserMode--topIndicator']
            : styles['UserMode--bottomIndicator'];

    return (
        <div
            className={classNames(
                styles.UserModeSwitch,
                props.theme === 'white' && styles['UserModeSwitch--white'],
                props.theme === 'green' && styles['UserModeSwitch--green'],
            )}
        >
            <button
                type="button"
                className={classNames(
                    styles.UserMode,
                    indicatorPositionClass,
                    isB2CUser && styles.UserModeActive,
                )}
                onClick={switchToB2C}
                aria-label="Privat"
                disabled={isB2CUser}
            >
                Privat
            </button>
            <div className={styles.Split} />
            <button
                type="button"
                className={classNames(
                    styles.UserMode,
                    indicatorPositionClass,
                    isB2BUser && styles.UserModeActive,
                )}
                onClick={switchToB2B}
                aria-label="Företag"
                disabled={isB2BUser}
            >
                Företag
            </button>
        </div>
    );
};
export default UserTypeModeSwitch;
