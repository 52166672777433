import { AnimatedBadge, BadgePositioning } from '@coop/components';
import { Bag1Icon } from '@coop/icons';
import type { FC } from 'react';
import * as React from 'react';
import { useEffect } from 'react';

import { appConfig } from '../../../../appConfig';
import { LocalStorageFacade } from '../../../../facades/storage/localStorageFacade';
import cartItemsSelectors from '../../../cart/selectors/cartItemsSelectors';
import { useCncFlyInState } from '../../../common/hooks/useCncFlyInState';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/useThunkDispatch';
import { storeSelectors } from '../../../common/selectors/storeSelectors';
import tracking from '../../../common/tracking/tracking';
import HeaderIconButton from '../HeaderIconButton';

const CartTrigger: FC<React.PropsWithChildren<unknown>> = () => {
    const dispatch = useAppDispatch();
    const { initCnc, isOpen } = useCncFlyInState();
    const postalCode = useAppSelector(storeSelectors.currentPostalCode);

    const openCnc = () => {
        tracking.cnc.openFrom.cartIcon(!!postalCode);
        initCnc();
    };

    const cartItemCount = useAppSelector(cartItemsSelectors.selectCartQuantity);

    useEffect(() => {
        const action = LocalStorageFacade.instance.get('Ecommerce:action');
        if (
            action === 'cnc' &&
            window.location.pathname.indexOf(appConfig.coopSettings.ecommerce.url) > -1
        ) {
            initCnc();
            LocalStorageFacade.instance.remove('Ecommerce:action');
        }
    }, [dispatch, initCnc]);

    return (
        <BadgePositioning>
            <HeaderIconButton
                icon={Bag1Icon}
                label={!cartItemCount ? 'Tom kundvagn' : `Kundvagn med ${cartItemCount} varor`}
                onClick={openCnc}
                data-test="showMiniCart"
                aria-haspopup="dialog"
                aria-expanded={isOpen}
                theme="grey"
            >
                <div aria-hidden="true">
                    <AnimatedBadge
                        color="ctaGreen"
                        size="small"
                        wide={cartItemCount > 99}
                        top={-6}
                        right={-6}
                        animationProps={{
                            key: cartItemCount,
                            in: !!cartItemCount,
                            timeout: 500,
                            appear: true,
                            enter: true,
                        }}
                    >
                        {cartItemCount}
                    </AnimatedBadge>
                </div>
            </HeaderIconButton>
        </BadgePositioning>
    );
};

export default CartTrigger;
