import { appConfig } from '../../../../appConfig';
import { appInsights } from '../../../../appInsights';
import callOnce from '../../../../utility/callOnce';
import { Cookies } from '../../../../utility/cookies';
import { GdprCookieVendors, hasAcceptedGdprCookieVendorsSync } from '../../../../utility/gdpr';
import { productApi } from '../../api/products/productApi';
import tracking from '../../tracking/tracking';
import { mapCustomCampaigns, mapProductRecommendations } from '../product/mapProductResponse';
import userFlow from '../userFlow';
import type { DySelectors } from './dynamicYield.types';

// leaving cookie name for cookie erase
export const DY_GLOBAL_CONTROL_GROUP_COOKIE_NAME = '_dy_gcg';
const DY_ID_COOKIE_NAME = '_dyid';
const DY_SESSION_COOKIE_NAME = '_dyjsession';

export const getStaticPageContext = () => {
    if (window.DY) {
        return DY.recommendationContext;
    }

    return null;
};

export const hasAcceptedDynamicYieldConsents = () => {
    return hasAcceptedGdprCookieVendorsSync([
        GdprCookieVendors.DynamicYield,
        GdprCookieVendors.GoogleAnalytics,
    ]);
};

export const hasAcceptedDynamicYieldConsentsWithAbTest = () => {
    return hasAcceptedGdprCookieVendorsSync([
        GdprCookieVendors.DynamicYield,
        GdprCookieVendors.GoogleAnalytics,
        GdprCookieVendors.DynamicYieldAbTest,
    ]);
};

export const getDynamicYieldData = (): ApiDYBaseRequest => {
    const dynamicYieldIds: ApiDYBaseRequest = {
        user: {
            active_consent_accepted: false,
        },
    };

    if (hasAcceptedDynamicYieldConsents()) {
        const dyId = Cookies.read(DY_ID_COOKIE_NAME);
        const dySession = Cookies.read(DY_SESSION_COOKIE_NAME);

        if (dyId) {
            dynamicYieldIds.user = {
                dyid: dyId,
                active_consent_accepted: true,
            };
        }

        if (dySession) {
            dynamicYieldIds.session = {
                dy: dySession,
            };
        }
    }

    return dynamicYieldIds;
};

const getCustomCampaigns = async <TCustomData>(
    id: string,
    pageContext: ApiDYPageContext,
    options?: { timeout: number },
) => {
    const dynamicYieldIds = getDynamicYieldData();

    const request: ApiDYCustomCampaignRequest = {
        ...dynamicYieldIds,
        selector: {
            names: [id],
        },
        context: {
            page: pageContext,
        },
        options: {
            returnAnalyticsMetadata: true,
        },
    };

    const response = await productApi.getCustomCampaigns(request, options);
    const customCampaigns = mapCustomCampaigns<TCustomData>(response);
    return customCampaigns;
};

const getCustomCampaign = async <TCustomData>(
    id: string,
    pageContext: ApiDYPageContext,
    options?: { timeout: number },
) => {
    const response = await getCustomCampaigns<TCustomData>(id, pageContext, options);

    const customCampaign = response.find((campaign) => campaign.selectorName === id);
    return customCampaign;
};

export interface AbTestCustomData<TTestValue> {
    isAbTest: boolean;
    chosenVariant?: TTestValue;
}

const isAbTest = async <TValue = string>(selector: DySelectors, options?: { timeout: number }) => {
    const defaultResponse: AbTestCustomData<TValue> = { isAbTest: false, chosenVariant: undefined };
    const abTestsGloballyEnabled = appConfig.coopSettings.ecommerce.enableDynamicYieldAbTests;
    const abTestDeniedSelectors =
        appConfig.coopSettings.ecommerce.dynamicYieldDeniedAbTestSelectors;

    if (!abTestsGloballyEnabled) {
        return defaultResponse;
    }

    if (abTestDeniedSelectors?.includes(selector)) {
        return defaultResponse;
    }

    if (hasAcceptedDynamicYieldConsentsWithAbTest()) {
        // in fact context is irrevelant but required by DY, so we just send current static one.
        const staticPageContext = getStaticPageContext();
        if (!staticPageContext) return defaultResponse;

        const context = {
            ...staticPageContext,
            location: window.location.href,
        };

        try {
            const campaign = await getCustomCampaign<AbTestCustomData<TValue>>(
                selector,
                context,
                options,
            );

            tracking.ecommerce.dynamicYield.campaign(selector, campaign?.analyticsMetadata);

            if (campaign?.custom?.chosenVariant != null) {
                return { isAbTest: true, chosenVariant: campaign.custom.chosenVariant };
            }

            if (campaign?.custom?.isAbTest) {
                return { isAbTest: true, chosenVariant: 'default' };
            }

            return defaultResponse;
        } catch {
            const error: Error = {
                name: 'DY abtest fail',
                message: `Error when trying to get ${selector}`,
                stack: undefined,
            };

            appInsights.trackException({ exception: error });

            return defaultResponse;
        }
    }

    return defaultResponse;
};

const getIsAbTest = <TTestValue = string>() =>
    callOnce((uniqueName, params, options) => isAbTest<TTestValue>(params, options));

interface RecommendationRequest {
    recommendationId: string;
    onlyPromotions: boolean;
}

const getProductRecommendations = async (
    recommendationRequests: Array<RecommendationRequest>,
    storeId: string,
    pageContext: ApiDYPageContext,
    pageAttributes?: unknown,
) => {
    const dynamicYieldIds = getDynamicYieldData();
    const args = recommendationRequests.reduce((prev, curr) => {
        return { ...prev, [curr.recommendationId]: { promotions: curr.onlyPromotions } };
    }, {});

    const recommendationNames = recommendationRequests.map((x) => x.recommendationId);

    const request: ApiDYProductRecommendationsRequest = {
        ...dynamicYieldIds,
        selector: {
            names: recommendationNames,
            args,
        },
        context: {
            page: pageContext,
            pageAttributes,
        },
        options: {
            returnAnalyticsMetadata: true,
        },
    };

    const customerGroups = await userFlow.getUserCustomerGroups();
    const response = await productApi.getRecommendations(storeId, request, customerGroups);
    const productRecommendatons = mapProductRecommendations(response);
    return productRecommendatons;
};

const getProductRecommendation = async (
    recommendationRequest: RecommendationRequest,
    storeId: string,
    pageContext: ApiDYPageContext,
    pageAttributes?: unknown,
) => {
    const productRecommendations = await getProductRecommendations(
        [recommendationRequest],
        storeId,
        pageContext,
        pageAttributes,
    );
    const productRecommendation = productRecommendations.find(
        (recommendation) => recommendation.selectorName === recommendationRequest.recommendationId,
    );

    if (!productRecommendation) return null;

    return productRecommendation;
};

const dynamicYieldFlow = {
    getIsAbTest,
    isAbTest,
    getCustomCampaign,
    getProductRecommendations,
    getProductRecommendation,
};

export default dynamicYieldFlow;
