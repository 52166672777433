import { Button } from '@coop/components';
import type { FC } from 'react';
import * as React from 'react';

import { ga4UserTracking } from '../../../common/tracking/ga4/user';
import { getLoginUrlWithReturnUrl } from '../../../common/utils/urlUtils';

interface LoginButtonProps {
    stayAfterLoginLogoutRedirect: boolean;
}
const LoginButton: FC<React.PropsWithChildren<LoginButtonProps>> = (props) => {
    const loginUrl = getLoginUrlWithReturnUrl(props.stayAfterLoginLogoutRedirect);

    return (
        <Button
            theme="primary"
            asChild
            onClick={() => {
                ga4UserTracking.login(null, 'start');
            }}
        >
            <a href={loginUrl}>Logga in</a>
        </Button>
    );
};

export default LoginButton;
