/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect } from 'react';

import type { ChosenVariantSelector, DySelectors } from '../flow/dynamicYield/dynamicYield.types';
import dynamicYieldFlow from '../flow/dynamicYield/dynamicYieldFlow';
import { useAsyncDispatcher } from './useAsyncDispatcher';

const useDynamicYieldAbTest = <TSelector extends DySelectors>(
    selector: TSelector,
    uniqueName?: string,
    options?: { timeout: number },
) => {
    const { execute, result, isLoading } = useAsyncDispatcher(
        () =>
            dynamicYieldFlow.getIsAbTest<ChosenVariantSelector<TSelector>>()(
                uniqueName || selector,
                selector,
                options,
            ),
        { initialLoadingState: true },
    );

    useEffect(() => {
        execute();
    }, [execute, selector, uniqueName]);

    return { abTestResponse: result, isLoadingAbTest: isLoading };
};

export default useDynamicYieldAbTest;
