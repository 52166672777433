import getDirectPersonalizationSearchAxiosClient, {
    prepareRequestParams,
} from '../../../search/api/directPersonalizationAxiosClient';

export const getProductsByIds = async (
    ids: string[],
    storeId: string,
    customerGroups?: string[],
    device?: string,
) => {
    const client = getDirectPersonalizationSearchAxiosClient(false);

    const response = await client.post<ApiLoopSearchResponse<ApiLoopProduct>>(
        '/search/entities/by-id',
        ids,
        { params: prepareRequestParams(storeId, customerGroups, device, false) },
    );
    return response.data?.results?.items || [];
};
