import { SessionStorageFacade } from '../../../../facades/storage/sessionStorageFacade';

const SESSION_KEY: string = 'customerGroups';
const getSessionKey = (userId: string) => {
    return `${SESSION_KEY}:${userId}`;
};

const customerGroupsSession = {
    get: (userId: string) => {
        return SessionStorageFacade.instance.getObject<string[]>(getSessionKey(userId));
    },
    add: (customerGroups: string[], userId: string) => {
        SessionStorageFacade.instance.addObject(getSessionKey(userId), customerGroups);
    },
    remove: (userId: string) => {
        SessionStorageFacade.instance.remove(getSessionKey(userId));
    },
};

export default customerGroupsSession;
