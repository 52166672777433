import { Cookies } from '../../../../../utility/cookies';
import { BUSINESS_TOGGLE_COOKIE } from '../../../persistance/cookie/cookieKeys';
import { sendEvent } from '../../utils';

const getCustomerSegment = () => {
    return Cookies.read(BUSINESS_TOGGLE_COOKIE) ? 'Företag' : 'Privat';
};

const showAllPointsHistory = () =>
    sendEvent({
        event: 'interaction',
        eventCategory: 'Poängtrappan',
        eventAction: 'Poänghistorik',
        customerSegment: getCustomerSegment(),
    });

const showWithdrawnPointsHistory = () =>
    sendEvent({
        event: 'interaction',
        eventCategory: 'Poängtrappan',
        eventAction: 'Uttagna poäng',
        customerSegment: getCustomerSegment(),
    });

const togglePositiveTransaction = () =>
    sendEvent({
        event: 'interaction',
        eventCategory: 'Poängtrappan',
        eventAction: 'Utökad flik insamlade poäng',
        customerSegment: getCustomerSegment(),
    });

const toggleNegativeTransaction = () =>
    sendEvent({
        event: 'interaction',
        eventCategory: 'Poängtrappan',
        eventAction: 'Utökad flik uttagna poäng',
        customerSegment: getCustomerSegment(),
    });

export default {
    showAllPointsHistory,
    showWithdrawnPointsHistory,
    togglePositiveTransaction,
    toggleNegativeTransaction,
};
