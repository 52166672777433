import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { appConfig } from '../../../appConfig';
import { Cookies } from '../../../utility/cookies';
import { GdprCookieVendors, hasAcceptedGdprCookieVendorsSync } from '../../../utility/gdpr';
import axiosClientFactory from '../../common/api/clients/clientFactory';
import type { CustomErrorInterceptor } from '../../common/api/clients/localApiAxiosErrorInterceptors';
import { createDefaultException } from '../../common/exceptions/exceptionUtils';

export const prepareRequestParams = (
    storeId: string,
    customerGroups: string[] | undefined,
    device: string | undefined,
    quickSearch: boolean | undefined,
    searchService: string | null = null,
) => {
    const requestParams = {
        store: storeId,
        groups: customerGroups && !!customerGroups.length ? customerGroups.join(',') : null,
        device,
        direct: !!quickSearch,
        searchService,
    };

    return requestParams;
};

const directSearchErrorAxiosInterceptor: CustomErrorInterceptor = (error) => {
    if (typeof error === 'string') {
        const defaultException = createDefaultException();
        defaultException.friendlyMessage = error;
        return defaultException;
    }

    return null;
};

const getDirectPersonalizationSearchAxiosClient = (withConsent: boolean = true): AxiosInstance => {
    const acceptedConsent = hasAcceptedGdprCookieVendorsSync([GdprCookieVendors.Loop54]);
    const loop54CookieName = 'Loop54AnonymousUser';

    const axiosRequestConfig: AxiosRequestConfig = {
        baseURL: appConfig.coopSettings.serviceAccess.personalizationApiUrl,
        headers: {
            'Ocp-Apim-Subscription-Key':
                appConfig.coopSettings.serviceAccess.personalizationApiSubscriptionKey,
        },
        params: {
            'api-version': appConfig.coopSettings.serviceAccess.personalizationApiVersion,
        },
    };

    if (withConsent) {
        axiosRequestConfig.transformRequest = [
            (data: { customData?: object }) => {
                const consentedData = {
                    ...data,
                    customData: {
                        ...data?.customData,
                        consent: acceptedConsent,
                    },
                };
                return consentedData;
            },
        ];

        if (axios.defaults.transformRequest) {
            axiosRequestConfig.transformRequest = [
                ...axiosRequestConfig.transformRequest,
                ...(Array.isArray(axios.defaults.transformRequest)
                    ? axios.defaults.transformRequest
                    : [axios.defaults.transformRequest]),
            ];
        }
    }

    const loopUserId = Cookies.read(loop54CookieName);

    if (acceptedConsent) {
        if (appConfig.coopUserSettings.userId) {
            // accepted consent and logged in
            axiosRequestConfig.headers['User-Id'] = appConfig.coopUserSettings.userId;
        }
        if (loopUserId) {
            // accepted consent and NOT logged in
            axiosRequestConfig.headers['Loop-User-Id'] = loopUserId;
        }
    }

    const directSearchAxiosClient = axiosClientFactory.createDirectApiClient(axiosRequestConfig, [
        directSearchErrorAxiosInterceptor,
    ]);

    directSearchAxiosClient.interceptors.response.use((response) => {
        if (acceptedConsent) {
            const headerId = response.headers['loop-user-id'];
            if (headerId) {
                Cookies.create(loop54CookieName, headerId, 365, true);
            }
        }

        return response;
    });

    return directSearchAxiosClient;
};

export default getDirectPersonalizationSearchAxiosClient;
