import { Search } from '@coop/components';
import type { FC } from 'react';
import * as React from 'react';
import { useEffect } from 'react';

import { useSearchOverlayGA4ActionTracking } from '../../../common/tracking/hooks/useSearchOverlayGA4Tracking';
import { useSearchStateContext } from '../../../search/contexts/searchStateContext';
import type { GlobalSearchResults } from '../../../search/models/models';
import { getFullSearchResultUrlWithOrder } from '../../../search/utils/searchResultUtils';

interface GlobalSearchBarProps {
    performFullSearch?: (newQuery: string, originalQuery?: string) => void;
    size: React.ComponentProps<typeof Search>['size'];
}

const GlobalSearchBar: FC<React.PropsWithChildren<GlobalSearchBarProps>> = (props) => {
    const {
        result,
        query,
        isOpen,
        close,
        show,
        isLoading,
        reset,
        open,
        setQuery,
        expectedSearchResultType,
        searchFromPage,
    } = useSearchStateContext<GlobalSearchResults>();

    const { trackEnterAction } = useSearchOverlayGA4ActionTracking(
        query,
        result?.queryUsed,
        searchFromPage,
        isLoading,
    );

    const navigateToGlobalSearchResultsPage = () => {
        trackEnterAction();

        let realQuery = isLoading ? query : result?.queryUsed;
        if (!realQuery) {
            realQuery = '';
        }
        if (props.performFullSearch) {
            close();
            props.performFullSearch(realQuery, query);
            return;
        }

        const viewAllResultsUrl = getFullSearchResultUrlWithOrder(
            realQuery,
            query,
            expectedSearchResultType,
        ).path;

        window.location.href = viewAllResultsUrl;
    };

    useEffect(() => {
        if (show) {
            open();
        }
    }, [show, open]);

    return (
        <Search
            inputText={query}
            setInputText={setQuery}
            useDropdown={false}
            size={props.size}
            hide={isOpen}
            inputPlaceholder="Sök recept, varor, butiker och artiklar"
            onEnterPress={navigateToGlobalSearchResultsPage}
            onEscapePress={reset}
            onReset={reset}
            theme="shadowed"
        />
    );
};

export default GlobalSearchBar;
