/* eslint-disable no-underscore-dangle */
// To understand the jargon: https://help.consentmanager.net/books/cmp/page/understanding-account-structure-vendors-purposes-and-cookies

import { Cookies } from './cookies';

export enum GdprCookiePurposes {
    Functional = 'c51', // Strictly neccessary, i.e. always activated
    Marketing = 'c52',
    Preferences = 'c53',
    Measuring = 'c54',
    Other = 'c55',
}

const CookieVendorsProduction = {
    Amazon: 's40',
    Cloudflare: 'c65',
    ConsentManager: 's23',
    COOP: 'c9233', // Stricly neccessary , i.e. always activated
    GoogleAds: 's1',
    GoogleAnalytics: 's26',
    GoogleGeneral: 's135',
    GoogleTagManager: 's905',
    HotJar: 's46',
    IABEurope_CMPDomain: 's24',
    MicrosoftAzure: 'c9245',
    Loop54: 'c11229',
    DynamicYield: 'c13463',
    DynamicYieldAbTest: 'c18593',
} as const;

const CookieVendorsTest = {
    MicrosoftAzure: 'c9968',
    Loop54: 'c11222',
    DynamicYield: 'c13452',
    DynamicYieldAbTest: 'c24124',
    GoogleAnalytics: 's26',
} as const;

type CookieVendorValues =
    | (typeof CookieVendorsProduction)[keyof typeof CookieVendorsProduction]
    | (typeof CookieVendorsTest)[keyof typeof CookieVendorsTest];

export const GdprCookieVendors = {
    MicrosoftAzure: window.isTestingOrDevEnv
        ? CookieVendorsTest.MicrosoftAzure
        : CookieVendorsProduction.MicrosoftAzure,

    Loop54: window.isTestingOrDevEnv ? CookieVendorsTest.Loop54 : CookieVendorsProduction.Loop54,

    DynamicYield: window.isTestingOrDevEnv
        ? CookieVendorsTest.DynamicYield
        : CookieVendorsProduction.DynamicYield,

    DynamicYieldAbTest: window.isTestingOrDevEnv
        ? CookieVendorsTest.DynamicYieldAbTest
        : CookieVendorsProduction.DynamicYieldAbTest,

    GoogleAnalytics: window.isTestingOrDevEnv
        ? CookieVendorsTest.GoogleAnalytics
        : CookieVendorsProduction.GoogleAnalytics,
} as const;

// seems like this one is firing on consent gather (load) and when there is a change to consent
export const triggerOnConsentGatheredGdpr = (callback: () => void) => {
    window.__cmp?.('addEventListener', ['consent', callback, false], null);
};

export const hasAcceptedGdprPurpose = (gdprPurpose: GdprCookiePurposes): boolean => {
    if (window.__cmp?.('getCMPData')?.purposeLI[gdprPurpose]) {
        return true;
    }

    return false;
};

export const hasAcceptedGdprCookieVendor = (gdprCookieVendor: CookieVendorValues): boolean => {
    if (window.__cmp?.('getCMPData')?.vendorConsents?.[gdprCookieVendor]) {
        return true;
    }

    return false;
};

export const syncConsentManagerVendors = () => {
    const vendorSync = () => {
        const ccmAcceptedVendors = getAcceptedVendors();
        const importantVendors = <string[]>[
            GdprCookieVendors.Loop54,
            GdprCookieVendors.DynamicYield,
            GdprCookieVendors.DynamicYieldAbTest,
            GdprCookieVendors.GoogleAnalytics,
        ];

        const vendorsForCookie = ccmAcceptedVendors.filter((x) => importantVendors.includes(x));

        // sync vendor consents, we need it synchronously somewhere else
        Cookies.create('coop-ccm-av', vendorsForCookie.join('|'), 365);
    };

    triggerOnConsentGatheredGdpr(vendorSync);
};

export const hasAcceptedGdprCookieVendorsSync = (vendors: CookieVendorValues[]) => {
    const consentCookie = Cookies.read('coop-ccm-av');
    if (!consentCookie) return false;

    if (!vendors) return false;

    const stringedVendors = vendors.map((vendor) => vendor.toString());
    const cookieVendors = consentCookie.split('|');

    const hasAccepted = stringedVendors.every((vendor) => cookieVendors.includes(vendor));
    return hasAccepted;
};

export const triggerOnAllConsentAccepted = (callback: Function) => {
    window.__cmp?.('addEventListener', ['consentapproved', callback, false], null);
};

export const triggerOnAllConsentRejected = (callback: Function) => {
    window.__cmp?.('addEventListener', ['consentrejected', callback, false], null);
};

export const triggerOnSingleConsentChange = (callback: Function) => {
    window.__cmp?.('addEventListener', ['consentcustom', callback, false], null);
};

export const getAcceptedVendors = () => {
    const cmpData = window.__cmp?.('getCMPData');
    if (cmpData) {
        return Object.keys(cmpData.vendorConsents);
    }

    return [];
};

export const triggerWhenGDPRConsentExists = ({
    cookieVendor,
    onConsentExists,
    onActiveDecline,
}: {
    onConsentExists: () => void;
    onActiveDecline: () => void;
    cookieVendor: CookieVendorValues;
}) => {
    const fallback = () =>
        triggerOnConsentGatheredGdpr(() => {
            if (hasAcceptedGdprCookieVendor(cookieVendor)) {
                onConsentExists();
            } else {
                onActiveDecline();
            }
        });

    window.addEventListener('load', () => {
        if (typeof window.__cmp === 'function') {
            if (hasAcceptedGdprCookieVendor(cookieVendor)) {
                onConsentExists();
            } else {
                fallback();
            }
        }
    });
};
