import { appConfig } from '../../../appConfig';
import { Cookies } from '../../../utility/cookies';
import { BUSINESS_TOGGLE_COOKIE } from '../persistance/cookie/cookieKeys';

export const getAnonymousCustomerGroups = (): string[] => {
    const customerGroups = [];

    if (appConfig.coopUserSettings.isCompany || Cookies.read(BUSINESS_TOGGLE_COOKIE)) {
        customerGroups.push('CUSTOMER_COMPANY');
    } else {
        customerGroups.push('CUSTOMER_PRIVATE');
    }

    return customerGroups;
};
