/* eslint-disable @typescript-eslint/no-throw-literal */
import { appConfig } from '../../../../appConfig';
import type { ApiCartException } from '../../exceptions/exceptionDefinitions';
import type { HybrisCart } from '../../models/hybris/hybrisCart';
import type { HybrisCartModification } from '../../models/hybris/hybrisCartModification';
import { HybrisResponseFieldsType } from '../../models/hybris/hybrisConstants';
import type { HybrisSwitchStore } from '../../models/hybris/hybrisSwitchStore';
import getDirectHybrisAxiosClient from '../clients/directHybrisAxiosClient';

const directCartApi = {
    addToCartFromOrder:
        (token: string) =>
        async (userId: string, cartId: string, orderId: string): Promise<HybrisCart> => {
            const response = await getDirectHybrisAxiosClient(token).post(
                `users/${userId}/carts/${cartId}/addfromorder/${orderId}`,
            );
            const resp = response.data;
            return resp;
        },

    updateCartItem:
        (token?: string) =>
        async (
            userId: string,
            cartId: string,
            item: {
                productId: string;
                variantId: string | undefined;
                quantity: number;
                itemReplaceable?: boolean;
            },
        ) => {
            const queryString = new URLSearchParams();
            queryString.set('qty', item.quantity.toString());
            queryString.set('code', item.productId);
            if (item.variantId) {
                queryString.set('varianceCode', item.variantId);
            }
            if (item.itemReplaceable !== undefined) {
                queryString.set('replaceable', item.itemReplaceable ? 'true' : 'false');
            }

            const response = await getDirectHybrisAxiosClient(token).post<HybrisCartModification>(
                `users/${encodeURIComponent(userId)}/carts/${encodeURIComponent(
                    cartId,
                )}/products?${queryString.toString()}`,
            );

            if (response.data.statusCode !== 'success') {
                let errorMessage = appConfig.coopSettings.ecommerce.errorMessage.general;
                switch (response.data.statusCode) {
                    case 'restrictedByUserGroupAndCategory':
                        errorMessage = appConfig.coopUserSettings.punchout.isPunchout
                            ? appConfig.coopSettings.ecommerce.errorMessage
                                  .productRestrictedForBusinessUnit
                            : appConfig.coopSettings.ecommerce.errorMessage
                                  .productRestrictedForPrivateUser;
                        break;
                    case 'unavailable':
                        errorMessage =
                            appConfig.coopSettings.ecommerce.errorMessage.productNotFound;
                        break;
                    case 'foodBagOnly':
                        break;
                    default:
                        break;
                }
                throw <ApiCartException>{ code: null, friendlyMessage: errorMessage, type: 'Cart' };
            }
            return response.data;
        },

    updateCartItemQuantity:
        (token: string) =>
        async (
            userId: string,
            cartId: string,
            item: {
                entryNumber: number;
                quantity: number;
            },
        ): Promise<boolean> => {
            const queryString = new URLSearchParams();
            queryString.set('qty', item.quantity.toString());

            const response = await getDirectHybrisAxiosClient(token).patch(
                `users/${userId}/carts/${cartId}/entries/${
                    item.entryNumber
                }?${queryString.toString()}`,
            );
            const resp = !!response.data;
            return resp;
        },

    deleteCartItem:
        (token: string) =>
        async (userId: string, cartId: string, entryNumber: number): Promise<boolean> => {
            const response = await getDirectHybrisAxiosClient(token).delete(
                `users/${userId}/carts/${cartId}/entries/${entryNumber}`,
            );
            const resp = !!response.data;
            return resp;
        },

    bulkEntries:
        (token: string) =>
        async (
            userId: string,
            cartId: string,
            items: { productCode: string; varianceCode: string | undefined; quantity: number }[],
        ) => {
            const response = await getDirectHybrisAxiosClient(token).post<{
                CartModifications: {}[];
            }>(`users/${userId}/carts/${cartId}/bulkentries`, items);
            const resp = response.data.CartModifications;
            return resp;
        },

    createCart: async (shoppingUserId: string, token?: string) => {
        const response = await getDirectHybrisAxiosClient(token).post<HybrisCart>(
            `users/${encodeURIComponent(shoppingUserId)}/carts`,
        );

        return response.data;
    },
    getCart: async (shoppingUserId: string, cartId: string, token?: string) => {
        const response = await getDirectHybrisAxiosClient(token).get<HybrisCart>(
            `users/${encodeURIComponent(shoppingUserId)}/carts/${encodeURIComponent(
                cartId,
            )}?fields=${HybrisResponseFieldsType.Full}`,
        );

        return response.data;
    },
    updatePostCode: async (
        shoppingUserId: string,
        cartId: string,
        postCode: string,
        baseStoreId: string,
        confirmed: boolean,
        token?: string,
    ) => {
        const queryString = new URLSearchParams();
        queryString.set('postCode', postCode);
        queryString.set('confirmed', confirmed ? 'true' : 'false');
        queryString.set('basestoreCode', baseStoreId);

        const response = await getDirectHybrisAxiosClient(token).put<HybrisSwitchStore | undefined>(
            `users/${encodeURIComponent(
                shoppingUserId,
            )}/carts/${cartId}/postcode?${queryString.toString()}`,
        );
        // if we change to the already current store in the cart, we get 200ok with no response, thats why i added updateresult == null as success :E
        return response.data;
    },
    setPointOfService: async (
        shoppingUserId: string,
        cartId: string,
        pickupPointId: string,
        confirmed: boolean,
        token?: string,
    ) => {
        const queryString = new URLSearchParams();
        queryString.set('posName', pickupPointId);
        queryString.set('confirmed', confirmed ? 'true' : 'false');

        const response = await getDirectHybrisAxiosClient(token).put<HybrisSwitchStore | undefined>(
            `users/${encodeURIComponent(
                shoppingUserId,
            )}/carts/${cartId}/pointofservice?${queryString.toString()}`,
        );
        return response.data;
    },
    setDeliveryMode: async (
        shoppingUserId: string,
        cartId: string,
        deliveryModeId: string,
        token?: string,
    ) => {
        const queryString = new URLSearchParams();
        queryString.set('deliveryModeId', deliveryModeId);

        await getDirectHybrisAxiosClient(token).put(
            `users/${encodeURIComponent(
                shoppingUserId,
            )}/carts/${cartId}/deliverymode?${queryString.toString()}`,
        );
    },
};

export default directCartApi;
