'use client';

import classNames from 'classnames';
import * as React from 'react';

import { useDelayedIsLoading } from './Loader.hooks';
import styles from './Loader.module.scss';
import type { LoaderSize, LoaderTheme } from './Loader.types';
import { getClassForSize, getClassForTheme } from './Loader.utils';

interface LoaderProps {
    size?: LoaderSize;
    additionalClassNames?: string;
    theme?: LoaderTheme;
    delay?: number;
    style?: React.CSSProperties;

    /** By default, loader is shown until unmounted, but you can control it with this prop */
    isLoading?: boolean;
    type?: 'balls' | 'spinner';
}

const Loader = ({
    theme = 'green',
    type = 'balls',
    ...props
}: React.PropsWithChildren<LoaderProps>) => {
    const enableDelay = !!props.delay;
    const showDelayedLoader = useDelayedIsLoading(props.isLoading, props.delay);

    if (props.isLoading === false) {
        return null;
    }

    if (enableDelay && !showDelayedLoader) {
        return null;
    }

    if (type === 'spinner') {
        return (
            <div
                className={classNames(
                    styles.Loader,
                    styles['Loader--spinner'],
                    props.additionalClassNames,
                    props.size && getClassForSize(props.size),
                )}
                style={props.style}
            >
                <div />
                <div />
                <div />
            </div>
        );
    }

    if (type === 'balls') {
        return (
            <div
                className={classNames(
                    styles.Loader,
                    styles['Loader--balls'],
                    props.additionalClassNames,
                    theme && getClassForTheme(theme),
                    props.size && getClassForSize(props.size),
                )}
                style={props.style}
            >
                <div className={styles['Loader-icon']}>
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        );
    }

    return null;
};

export default Loader;
