import classNames from 'classnames';
import * as React from 'react';

import ComponentPortals from '../../molecules/ComponentPortals';

interface StickyBottomProps {
    className?: string;
    usePortal?: boolean;
    children: React.ReactNode;
}

const StickyBottom = (props: StickyBottomProps) => {
    if (props.usePortal) {
        return <ComponentPortals cmpName="StickyBottom">{props.children}</ComponentPortals>;
    }

    return <div className={classNames('StickyBottom', props.className)}>{props.children}</div>;
};

export default StickyBottom;
