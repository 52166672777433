import * as React from 'react';
import { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import { v4 } from 'uuid';

interface ModalContextStateType {
    id: string;
    headerId: string;
    descriptionId?: string;
    parentId?: string;
    hasOtherModalsAsChildren: boolean;
}

type ModalContextDispatchActions =
    | { type: 'set_has_children'; modalHasModalAsChild: boolean }
    | {
          type: 'set_parent_id';
          parentId: string | undefined;
      };
type ModalContextDiapatchType = React.Dispatch<ModalContextDispatchActions>;

export const ModalContextState = createContext<ModalContextStateType | null>(null);

const ModalContextDispatch = createContext<ModalContextDiapatchType | null>(null);

const reducer = (
    state: ModalContextStateType,
    action: ModalContextDispatchActions,
): ModalContextStateType => {
    switch (action.type) {
        case 'set_has_children':
            return { ...state, hasOtherModalsAsChildren: action.modalHasModalAsChild };
        case 'set_parent_id':
            return { ...state, parentId: action.parentId };
        default:
            return state;
    }
};

const ModalContextProvider = (
    props: React.PropsWithChildren<{ idForDebug?: string; isOpen: boolean }>,
) => {
    const maybeParentModalContextState = useContext(ModalContextState);
    const maybeParentModalContextDispatch = useContext(ModalContextDispatch);

    const id = useRef(props.idForDebug || v4());

    const idFromParent = maybeParentModalContextState?.id || undefined;

    const [state, dispatch] = useReducer(reducer, {
        id: id.current,
        headerId: `${id.current}_header`,
        descriptionId: `${id.current}_description`,
        hasOtherModalsAsChildren: false,
        parentId: idFromParent,
    });

    useEffect(() => {
        dispatch({
            type: 'set_parent_id',
            parentId: idFromParent,
        });
    }, [idFromParent]);

    useEffect(() => {
        if (maybeParentModalContextDispatch) {
            maybeParentModalContextDispatch({
                type: 'set_has_children',
                modalHasModalAsChild: props.isOpen && !!state.parentId,
            });
        }
    }, [maybeParentModalContextDispatch, props.isOpen, state.parentId]);

    return (
        <ModalContextDispatch.Provider value={dispatch}>
            <ModalContextState.Provider value={state}>{props.children}</ModalContextState.Provider>
        </ModalContextDispatch.Provider>
    );
};
export default ModalContextProvider;
