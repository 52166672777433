import type { HybrisCart } from '../../models/hybris/hybrisCart';
import type { HybrisSavedCartResultList } from '../../models/hybris/hybrisSavedCartResultList';
import getDirectHybrisAxiosClient from '../clients/directHybrisAxiosClient';

const savedCartsApi = {
    restoreSavedCart:
        (token: string) =>
        async (userId: string, cartId: string, savedCartId: string): Promise<HybrisCart> => {
            const response = await getDirectHybrisAxiosClient(token).post(
                `users/${userId}/carts/${cartId}/restoresavedcart?savedCartId=${savedCartId}`,
            );
            const resp = response.data;
            return resp;
        },

    getSavedCarts:
        (token: string) =>
        async (
            userId: string,
            page: number,
            pageSize: number,
        ): Promise<HybrisSavedCartResultList> => {
            const response = await getDirectHybrisAxiosClient(token).get(
                `users/${userId}/carts/savedcarts?currentPage${encodeURIComponent(
                    page,
                )}&pageSize=${encodeURIComponent(pageSize)}`,
            );
            const resp = response.data;
            return resp;
        },
    deleteSavedCart:
        (token: string) =>
        async (userId: string, cartId: string): Promise<boolean> => {
            const response = await getDirectHybrisAxiosClient(token).patch(
                `users/${userId}/carts/${cartId}/flagForDeletion`,
            );
            const resp = !!response.data;
            return resp;
        },

    bulkEntries:
        (token: string) =>
        async (
            userId: string,
            cartId: string,
            items: { productCode: string; variantCode: string; quantity: number }[],
        ): Promise<HybrisSavedCartResultList> => {
            const response = await getDirectHybrisAxiosClient(token).post(
                `users/${userId}/carts/${cartId}/bulkentries`,
                items,
            );
            const resp = response.data;
            return resp;
        },
};
export default savedCartsApi;
