/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';

import type { BadgeProps } from './Badge.types';
import { getBadgeClasses } from './Badge.utils';

const Badge = (props: React.PropsWithChildren<BadgeProps>) => {
    const classes = getBadgeClasses(props);

    return (
        <div
            className={classes}
            style={{
                top: props.top,
                right: props.right,
                bottom: props.bottom,
                left: props.left,
                transform: [
                    props.offsetX !== undefined && `translateX(${props.offsetX})`,
                    props.offsetY !== undefined && `translateY(${props.offsetY})`,
                ]
                    .filter(Boolean)
                    .join(' '),
            }}
        >
            {props.children}
        </div>
    );
};

export default Badge;
