import { ensureTrailingSlash } from '../../common/utils/urlUtils';
import getDirectContentApiClient from './contentApiClient';

const baseUrl = '/api/episerver/v3.0/content/';

export interface ContentParams<T> {
    expand?: (keyof T | '*')[];
    select?: (keyof T)[];
    depth: number;
}

export interface GetChildParams<T> {
    /** must end with slash / */
    url?: string;
    contentReferenceId?: string;
    expand?: (keyof T | '*')[];
    select?: (keyof T)[];
}

const mapContentParams = <T>(params: ContentParams<T>) => ({
    ...params,
    select: params?.select?.map((s) => encodeURIComponent(s.toString())).join(','),
    expand: params?.expand?.map((s) => encodeURIComponent(s.toString())).join(','),
});

const getContentByUrl = async <T extends IContent>(
    url: string,
    params: ContentParams<T> = { depth: 1 },
): Promise<T> => {
    const client = getDirectContentApiClient({
        baseURL: '', // dont add default /api here, as this endpoint needs to have URL relative to the origin
    });

    const urlPath = ensureTrailingSlash(url);

    const response = await client.get<T>(`${urlPath}extended`, {
        params: mapContentParams<T>(params),
    });

    return response.data;
};

const getContentExtended = async <T extends IContent>(
    contentReferenceId: string,
    params: ContentParams<T> = { depth: 1 },
): Promise<T> => {
    const client = getDirectContentApiClient({ baseURL: baseUrl });
    const response = await client.get<T>(`${contentReferenceId}/extended`, {
        params: mapContentParams<T>(params),
    });
    return response.data;
};

const getContent = async <T extends IContent>(
    contentReferenceId: string,
    params: ContentParams<T> = { depth: 1 },
): Promise<T> => {
    const client = getDirectContentApiClient({ baseURL: baseUrl });
    const response = await client.get<T>(`${encodeURIComponent(contentReferenceId)}/extended`, {
        params: mapContentParams<T>(params),
    });
    return response.data;
};

const getMultipleContents = async <T extends IContent>(
    contentReferenceIds: string[],
    params: ContentParams<T> = { depth: 1 },
): Promise<T[]> => {
    const client = getDirectContentApiClient({ baseURL: baseUrl });

    const response = await client.get<T[]>(`multiple/extended`, {
        params: {
            ...mapContentParams<T>(params),
            references: `${contentReferenceIds.map((s) => encodeURIComponent(s)).join(',')}`,
        },
    });
    return response.data;
};

const getChildren = async <T extends IContent>(props: GetChildParams<T>): Promise<T[]> => {
    const searchParams = new URLSearchParams();
    if (props.select)
        searchParams.set(
            'select',
            props.select.map((s) => encodeURIComponent(s.toString())).join(','),
        );
    if (props.expand)
        searchParams.set(
            'expand',
            props.expand.map((s) => encodeURIComponent(s.toString())).join(','),
        );

    const serializedParams = searchParams.toString();
    let requestUrl;
    if (props.url) {
        requestUrl = `${props.url}children${serializedParams ? `?${searchParams.toString()}` : ''}`;
    } else {
        requestUrl = `${baseUrl}${props.contentReferenceId}/children${
            serializedParams ? `?${searchParams.toString()}` : ''
        }`;
    }

    const client = getDirectContentApiClient({ baseURL: '' });
    const response = await client.get<T[]>(requestUrl);
    return response.data;
};

type ContentMenuNodeType = 'Link' | 'Shortcut';

export interface ContentMenuNode {
    id: number;
    name: string;
    url: string;
    segmentUrl: string;
    ancestors: ContentMenuNode[];
    nodes: ContentMenuNode[];
    type: ContentMenuNodeType;
}

const getMenu = async (
    contentReferenceId: string,
    depth: number = 1,
    withAncestors: boolean = false,
    withContainers: boolean = false,
): Promise<ContentMenuNode> => {
    const searchParams = new URLSearchParams();
    searchParams.set('depth', depth.toString());
    searchParams.set('withAncestors', withAncestors.toString());
    searchParams.set('withContainers', withContainers.toString());
    const serializedParams = searchParams.toString();
    const url = `${contentReferenceId}/menu${
        serializedParams ? `?${searchParams.toString()}` : ''
    }`;

    const client = getDirectContentApiClient({ baseURL: baseUrl });
    const response = await client.get<ContentMenuNode>(url);
    return response.data;
};

const getMenuByUrl = async (
    url: string,
    depth: number = 1,
    withAncestors: boolean = false,
    withContainers: boolean = false,
): Promise<ContentMenuNode> => {
    const searchParams = new URLSearchParams();
    searchParams.set('depth', depth.toString());
    searchParams.set('withAncestors', withAncestors.toString());
    searchParams.set('withContainers', withContainers.toString());
    const serializedParams = searchParams.toString();
    const apiUrl = `${url}menu${serializedParams ? `?${searchParams.toString()}` : ''}`;

    const client = getDirectContentApiClient();
    const response = await client.get<ContentMenuNode>(apiUrl);
    return response.data;
};

export const contentDeliveryApi = {
    getContentByUrl,
    getContent,
    getContentExtended,
    getMultipleContents,
    getChildren,
    getMenu,
    getMenuByUrl,
};
