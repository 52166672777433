import { Notification } from '@coop/components';
import type { FC } from 'react';
import * as React from 'react';

import { useGroupError } from '../../../hooks/useGroupError';
import type { ErrorGroup } from '../../../store/structureDefinitions/errorsState';

interface ErrorGroupNotificationProps {
    errorGroup: ErrorGroup;
    hideAfterMiliseconds: number;
    onClose?: () => void;
}

const ErrorGroupNotification: FC<React.PropsWithChildren<ErrorGroupNotificationProps>> = (
    props,
) => {
    const { firstError: globalFlyInError, clearGroupErrors: clearGlobalFlyInError } = useGroupError(
        props.errorGroup,
    );

    return (
        <Notification
            type="alert"
            message={globalFlyInError?.friendlyMessage}
            show={!!globalFlyInError}
            autoClose={!!props.hideAfterMiliseconds}
            close={props.onClose || clearGlobalFlyInError}
            hideAfterMiliseconds={props.hideAfterMiliseconds}
        />
    );
};

export default ErrorGroupNotification;
