/* eslint-disable @typescript-eslint/no-explicit-any */
type AppConfig = {
    coopSettings: CoopSettings;
    coopUserSettings: CoopUserSettings;
};

export const appConfig: AppConfig = {
    coopSettings: (window as any).coopSettings,
    coopUserSettings: (window as any).coopUserSettings,
};

type GetAppConfigParams = {};
export const getAppConfig = (params: GetAppConfigParams) => {
    return appConfig;
};
