import { useCallback, useContext, useEffect, useRef } from 'react';
import { RemoveScroll } from 'react-remove-scroll';

import { ModalContextState } from './ModalContext';

const removeScroll = (element: Element, scrollIsRemoved: boolean) => {
    if (element) {
        if (scrollIsRemoved) {
            // Right css property is used instead of padding-right as the element might have a padding-right already. To check for that takes too much time and flicker appears.
            element.classList.add(RemoveScroll.classNames.zeroRight);
        } else {
            // Remove class as the element might not be fixed all the time.
            element.classList.remove(RemoveScroll.classNames.zeroRight);
        }
    }
};

const fixedElementSelectors = ['.HeaderContainer.is-fixed', 'footer.is-fixed'];

const removeScrollFromFixed = (scrollIsRemoved: boolean) => {
    // Prevent elements with  class from moving when body scrollbar gets removed.
    document.querySelectorAll(fixedElementSelectors.join(', ')).forEach((fixedElement) => {
        removeScroll(fixedElement, scrollIsRemoved);
    });
};

export const useRemoveScrollOnFixedElements = (when: boolean = true) => {
    useEffect(() => {
        removeScrollFromFixed(when);

        return () => removeScrollFromFixed(false);
    }, [when]);
};

/**
 * Traps the users focus made with tab and shift+tab on the ref
 * caution: clicking outside the ref area will cause the focus to not be trapped
 */
export const useTrapFocus = <TElement extends HTMLElement>(onEscape: () => void) => {
    const ref = useRef<TElement>(null);

    // We need a getFunction because querySelectorAll returns a snapshot and our React children
    // can update its children nodes or not completely rendered
    const getElements = useCallback(
        () => [
            ...Array.from(ref.current?.querySelectorAll<HTMLElement>(ACCEPTABLE_SELECTORS) || []),
        ],
        [],
    );

    const ACCEPTABLE_SELECTORS = 'a[href], button, textarea, input, select, [tabindex]';

    useEffect(() => {
        const focusIsOutsideOfTrap = (acceptableElements: Element[]) =>
            document.activeElement && !acceptableElements.includes(document.activeElement);

        const handleNext = (event: Event) => {
            const foundElements = getElements();
            if (!foundElements?.length) {
                return;
            }

            const currentFocusIsLast =
                foundElements[foundElements.length - 1] === document.activeElement;

            if (currentFocusIsLast || focusIsOutsideOfTrap(foundElements)) {
                event.preventDefault();
                foundElements[0].focus();
            }
        };

        const handlePrev = (event: Event) => {
            const foundElements = getElements();
            if (!foundElements?.length) {
                return;
            }

            const currentFocusIsFirst = foundElements[0] === document.activeElement;

            if (currentFocusIsFirst || focusIsOutsideOfTrap(foundElements)) {
                event.preventDefault();
                foundElements[foundElements.length - 1].focus();
            }
        };

        const handleUserKeyPress = (event: KeyboardEvent) => {
            if (event.key?.toLowerCase() === 'Escape'.toLowerCase() && onEscape) {
                onEscape();
                return;
            }

            if (!event.shiftKey && event.key?.toLowerCase() === 'Tab'.toLowerCase()) {
                handleNext(event);
            }

            if (event.shiftKey && event.key?.toLowerCase() === 'Tab'.toLowerCase()) {
                handlePrev(event);
            }
        };

        window.addEventListener('keydown', handleUserKeyPress);

        return () => {
            window.removeEventListener('keydown', handleUserKeyPress);
        };
    }, [onEscape, getElements]);

    return ref;
};

/**
 * Used to check if a component is mounted inside a modal
 */
export const useIsInsideModal = () => {
    const maybeParentModalContext = useContext(ModalContextState);
    const isInsideAnotherModal = !!maybeParentModalContext;

    return isInsideAnotherModal;
};
