import { SearchIcon } from '@coop/icons';
import classNames from 'classnames';
import type { FC, ReactNode } from 'react';
import * as React from 'react';

import { appConfig } from '../../../../appConfig';
import { BrowserSize } from '../../../../utility/browser';
import { HeaderCartErrorNotice } from '../../../cart/components/molecules/CartErrorNotice';
import { useFromBrowserMediaQuery } from '../../../common/components/atoms/ResponsiveBreakpoints';
import { useAppSelector } from '../../../common/hooks/useThunkDispatch';
import { useUserTypeQuery } from '../../../common/hooks/useUserType';
import { userProfileSelectors } from '../../../common/selectors/userProfileSelectors';
import { UserType } from '../../../common/store/structureDefinitions/userState';
import HeaderIconButton from '../HeaderIconButton';
import type { SiteNavigationItem } from './FeaturedNavigation';
import FeaturedNavigation from './FeaturedNavigation';
import { useScrolDirection } from './Header.hooks';
import HeaderMainNav from './HeaderMainNav';
import LoginButton from './LoginButton';
import MyCoopFlyInTrigger from './MyCoopFlyInTrigger';
import MyFavoritesDropdownTrigger from './MyFavoritesDropdownTrigger';
import PreHeader from './PreHeader';

interface HeaderProps {
    preHeaderText: string;
    showB2BSwitch: boolean;
    featuredNavigationItems: SiteNavigationItem[];
    topLevelNavigationItems: SiteNavigationItem[];
    stayAfterLoginLogoutRedirect: boolean;
    searchBarInHeaderSlot: ReactNode;
    useSearchToggler?: boolean;
    onSearchToggleClick?: () => void;
    searchToggleIsActive?: boolean;
}

const Header: FC<React.PropsWithChildren<HeaderProps>> = (props) => {
    const isFromLarge = useFromBrowserMediaQuery(BrowserSize.lg);
    const scrollDirection = useScrolDirection();

    const { data: userType } = useUserTypeQuery();
    const isPunchout = userType === UserType.punchout;
    const hidePreHeader = useAppSelector((state) => state.ui.shared.featureFlags.hidePreHeader);

    const disableSiteNavigation = isPunchout || appConfig.coopUserSettings.isShoppingForMunicipal;
    const disableMyFavoritesMenu = isPunchout || appConfig.coopUserSettings.isShoppingForMunicipal;

    const fullUserName = useAppSelector(userProfileSelectors.fullName);
    const profile = useAppSelector(userProfileSelectors.profile);
    const isAuthenticated = useAppSelector(userProfileSelectors.isAuthenticated);
    const isMember = useAppSelector(userProfileSelectors.isMember);

    return (
        <>
            {!hidePreHeader && isFromLarge && (
                <PreHeader
                    preHeaderText={props.preHeaderText}
                    showB2BSwitch={props.showB2BSwitch}
                    fullUserName={fullUserName}
                    isAuthenticated={isAuthenticated}
                    isMember={isMember}
                    isCompany={!!profile?.isCompany}
                />
            )}

            <div className={classNames('Header Header--black')} style={{ zIndex: 2 }}>
                <HeaderMainNav
                    stayAfterLoginLogoutRedirect={props.stayAfterLoginLogoutRedirect}
                    disableSiteNavigation={false}
                    logoColor={isPunchout ? 'white' : 'green'}
                    rightSlot={
                        <>
                            {!appConfig.coopUserSettings.isAuthenticated ? (
                                <LoginButton
                                    stayAfterLoginLogoutRedirect={
                                        props.stayAfterLoginLogoutRedirect
                                    }
                                />
                            ) : (
                                <>
                                    {!disableMyFavoritesMenu && <MyFavoritesDropdownTrigger />}
                                    <MyCoopFlyInTrigger />
                                </>
                            )}
                            {props.useSearchToggler && props.onSearchToggleClick && (
                                <HeaderIconButton
                                    icon={SearchIcon}
                                    label="Sök"
                                    isActive={props.searchToggleIsActive}
                                    onClick={props.onSearchToggleClick}
                                    theme="greyBorder"
                                />
                            )}
                        </>
                    }
                    centerSlot={
                        isFromLarge && (
                            <div
                                className={classNames(
                                    'Header-group Header-group--spaceAround16',
                                    'Header-group--translateY',
                                    !!props.searchBarInHeaderSlot &&
                                        scrollDirection === 'down' &&
                                        'is-translated',
                                )}
                            >
                                <div className="Header-group--translatedWrapper">
                                    {!disableSiteNavigation ? (
                                        <FeaturedNavigation items={props.featuredNavigationItems} />
                                    ) : (
                                        <div style={{ height: '100%', minWidth: 500 }} />
                                    )}
                                    {!!props.searchBarInHeaderSlot && (
                                        <div className="SearchWrapper">
                                            {props.searchBarInHeaderSlot}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    }
                    navigationItems={props.topLevelNavigationItems}
                />

                <HeaderCartErrorNotice />
            </div>
        </>
    );
};

export default Header;
