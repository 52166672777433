import { appConfig } from '../appConfig';
import { DY_GLOBAL_CONTROL_GROUP_COOKIE_NAME } from '../microApps/common/flow/dynamicYield/dynamicYieldFlow';
import { Cookies } from './cookies';
import {
    GdprCookieVendors,
    hasAcceptedGdprCookieVendor,
    triggerOnAllConsentAccepted,
    triggerOnAllConsentRejected,
    triggerOnConsentGatheredGdpr,
    triggerOnSingleConsentChange,
} from './gdpr';

const DY_SERVER_COOKIE_NAME = '_dyid_server';

const consentRejected = () => {
    // erase cookie that we add ourselves, rest is removed by DY scripts
    Cookies.eraseCookie(DY_SERVER_COOKIE_NAME);
    Cookies.eraseCookie(DY_GLOBAL_CONTROL_GROUP_COOKIE_NAME);
    DYO.ActiveConsent.updateConsentAcceptedStatus(false);
};

const updateDYConsent = (withSpaEvent: boolean) => {
    if (
        hasAcceptedGdprCookieVendor(GdprCookieVendors.DynamicYield) &&
        hasAcceptedGdprCookieVendor(GdprCookieVendors.GoogleAnalytics)
    ) {
        DYO.ActiveConsent.updateConsentAcceptedStatus(true);
        if (isDYEnabled() && withSpaEvent) {
            trackSpaDY(window.DY.recommendationContext, window.location.href, false);
        }
    } else {
        consentRejected();
    }
};

const isDYEnabled = () => {
    return window.DY && window.DY.API;
};

const fireIfEnabled = (func: Function) => {
    if (isDYEnabled()) {
        func();
    }
};

export const trackProfileDY = () => {
    if (appConfig.coopUserSettings.isAuthenticated) {
        fireIfEnabled(() =>
            DY.API('event', {
                name: 'Profile',
                properties: {
                    userType: appConfig.coopUserSettings.isCompany ? 'b2b' : 'b2c',
                },
            }),
        );
    }
};

const calculateTrackingData = (productData: ProductTrackingData) => {
    let { quantity } = productData;
    if (productData.previousQuantity) {
        quantity = Math.abs(productData.quantity - productData.previousQuantity);
    }

    const trackedPrice = productData.promotionPrice
        ? productData.promotionPrice
        : productData.price;
    const price = trackedPrice && (quantity * (trackedPrice * 100)) / 100;

    return {
        price,
        quantity,
    };
};

export const trackAddToCartDY = (productData: ProductTrackingData) => {
    const { price, quantity } = calculateTrackingData(productData);

    fireIfEnabled(() =>
        DY.API('event', {
            name: 'Add to Cart',
            properties: {
                dyType: 'add-to-cart-v1',
                value: price,
                currency: 'SEK',
                productId: productData.identifier,
                quantity,
            },
        }),
    );
};

export const trackAddToCartListDY = (
    recommendationId: string,
    productData: ProductTrackingData,
) => {
    const { price, quantity } = calculateTrackingData(productData);

    fireIfEnabled(() =>
        DY.API('event', {
            name: `Add to Cart - ${recommendationId}`,
            properties: {
                value: price,
                productId: productData.identifier,
                quantity,
            },
        }),
    );
};

export const trackRemoveFromCartDY = (productData: ProductTrackingData) => {
    const { price, quantity } = calculateTrackingData(productData);

    fireIfEnabled(() =>
        DY.API('event', {
            name: 'Remove from Cart',
            properties: {
                dyType: 'remove-from-cart-v1',
                value: price,
                currency: 'SEK',
                productId: productData.identifier,
                quantity,
            },
        }),
    );
};

export const trackRemoveFromCartListDY = (
    recommendationId: string,
    productData: ProductTrackingData,
) => {
    const { price, quantity } = calculateTrackingData(productData);

    fireIfEnabled(() =>
        DY.API('event', {
            name: `Remove from Cart - ${recommendationId}`,
            properties: {
                value: price,
                productId: productData.identifier,
                quantity,
            },
        }),
    );
};

export const trackPurchaseDY = (purchaseData: PurchaseTrackingData) => {
    fireIfEnabled(() =>
        DY.API('event', {
            name: 'Purchase',
            properties: {
                uniqueTransactionId: purchaseData.transactionId,
                dyType: 'purchase-v1',
                value: purchaseData.totalValue,
                currency: 'SEK',

                cart: purchaseData.cartItems.map((item) => ({
                    productId: item.identifier,
                    quantity: item.quantity,
                    itemPrice: item.promotionPrice ? item.promotionPrice : item.price, // itemPrice is per quantity of one
                })),
            },
        }),
    );
};

export const trackFilterItemsDY = (data: ProductFilterTrackingData) => {
    fireIfEnabled(() =>
        DY.API('event', {
            name: 'Filter Items',
            properties: {
                dyType: 'filter-items-v1',
                filterType: data.type,
                filterStringValue: data.value,
            },
        }),
    );
};

export const trackSortItemsDY = (data: ProductSortTrackingData) => {
    fireIfEnabled(() =>
        DY.API('event', {
            name: 'Sort Items',
            properties: {
                dyType: 'sort-items-v1',
                sortBy: data.sortBy,
                sortOrder: data.sortOrder,
            },
        }),
    );
};

export const trackSearchKeywordDY = (data: SearchKeywordTrackingData) => {
    fireIfEnabled(() =>
        DY.API('event', {
            name: 'Keyword Search',
            properties: {
                dyType: 'keyword-search-v1',
                keywords: data.searchTerm,
            },
        }),
    );
};

export const trackLoginDY = () => {
    if (appConfig.coopUserSettings.isAuthenticated) {
        fireIfEnabled(() =>
            DY.API('event', {
                name: 'Login',
                properties: {
                    dyType: 'login-v1',
                    cuid: appConfig.coopUserSettings.shoppingUserId,
                    cuidType: 'id', // id is also used as cuidType in the mobile app, thats why its used
                },
            }),
        );
    }
};

export const trackIdentifyDY = () => {
    if (appConfig.coopUserSettings.isAuthenticated) {
        fireIfEnabled(() =>
            DY.API('event', {
                name: 'Identify',
                properties: {
                    dyType: 'identify-v1',
                    cuid: appConfig.coopUserSettings.shoppingUserId,
                    cuidType: 'id', // id is also used as cuidType in the mobile app, thats why its used
                },
            }),
        );
    }
};

export const trackSignUpDY = (data: UserIdTrackingData) => {
    fireIfEnabled(() =>
        DY.API('event', {
            name: 'Signup',
            properties: {
                dyType: 'signup-v1',
                cuid: data.userId,
            },
        }),
    );
};

export const trackSyncCartDY = (data: SyncCartTrackingData) => {
    fireIfEnabled(() =>
        DY.API('event', {
            name: 'Sync cart',
            properties: {
                dyType: 'sync-cart-v1',
                currency: 'SEK',
                cart: data.items.map((item) => ({
                    productId: item.identifier,
                    quantity: item.quantity,
                    itemPrice: item.price,
                })),
            },
        }),
    );
};

export const trackSpaDY = (
    data: DynamicYieldPageContext,
    url: string,
    countAsPageview: boolean = true,
) => {
    fireIfEnabled(() =>
        DY.API('spa', {
            context: data,
            url,
            countAsPageview,
        }),
    );
};

export const updateDyStaticContext = (context: DynamicYieldPageContext) => {
    fireIfEnabled(() => {
        DY.recommendationContext = context;
    });
};

export const createCartPageContext = (url: string, cartProductIds: string[]) => ({
    location: url,
    type: 'CART',
    data: [...cartProductIds],
});

export const createOtherPageContext = (url: string) => ({
    location: url,
    type: 'OTHER',
    data: [],
});

export const createHomePageContext = (url: string) => ({
    location: url,
    type: 'HOMEPAGE',
    data: [],
});

export const createProductPageContext = (url: string, productId: string) => ({
    location: url,
    type: 'PRODUCT',
    data: [productId],
});

export const createCategoryPageContext = (url: string, categoryNamesList: string[]) => ({
    location: url,
    type: 'CATEGORY',
    data: [...categoryNamesList],
});

const initializeDynamicYield = () => {
    trackIdentifyDY();
    triggerOnConsentGatheredGdpr(() => updateDYConsent(false));
    triggerOnAllConsentAccepted(() => {
        DYO.ActiveConsent.updateConsentAcceptedStatus(true);
        if (isDYEnabled()) {
            trackSpaDY(window.DY.recommendationContext, window.location.href, false);
        }
    });
    triggerOnAllConsentRejected(consentRejected);
    triggerOnSingleConsentChange(() => updateDYConsent(true));
    trackProfileDY();
};

export default initializeDynamicYield;
