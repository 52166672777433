import type { FC, ReactNode } from 'react';
import * as React from 'react';

import { useAppSelector } from '../../../common/hooks/useThunkDispatch';
import LoginReminderTooltip from '../LoginReminderTooltip';
import CncTrigger from './CncTrigger';
import type { SiteNavigationItem } from './FeaturedNavigation';
import HeaderLeftNav from './HeaderLeftNav';

interface HeaderMainNavProps {
    navigationItems: SiteNavigationItem[];
    stayAfterLoginLogoutRedirect: boolean;
    disableSiteNavigation: boolean;
    centerSlot?: ReactNode;
    rightSlot?: ReactNode;
    logoColor: 'green' | 'white';
}
const HeaderMainNav: FC<React.PropsWithChildren<HeaderMainNavProps>> = (props) => {
    const hideLoginReminder = useAppSelector((state) => state.ui.shared.hideLoginReminder);

    return (
        <>
            <div className="Header-mainNav">
                <div className="Header-group Header-group--space16">
                    <HeaderLeftNav
                        disableSiteNavigation={props.disableSiteNavigation}
                        navigationItems={props.navigationItems}
                        logoColor={props.logoColor}
                    />
                </div>
                {props.centerSlot || <div className="Header-group" />}

                {props.rightSlot ? (
                    <div className="Header-group Header-group--space12">
                        {props.rightSlot}
                        <CncTrigger />
                    </div>
                ) : (
                    <div className="Header-group" />
                )}
            </div>
            {!hideLoginReminder && (
                <LoginReminderTooltip
                    stayAfterLoginLogoutRedirect={props.stayAfterLoginLogoutRedirect}
                />
            )}
        </>
    );
};

export default HeaderMainNav;
