import { Icon, OverlayLoader } from '@coop/components';
import { CoopLogoIcon, Menu1Icon } from '@coop/icons';
import classNames from 'classnames';
import * as React from 'react';

import { appConfig } from '../../../../appConfig';
import { FlyIn } from '../../../common/components/atoms/Modal';
import useModal from '../../../common/hooks/useModal';
import { useAppSelector } from '../../../common/hooks/useThunkDispatch';
import { useUserTypeQuery } from '../../../common/hooks/useUserType';
import { storeSelectors } from '../../../common/selectors/storeSelectors';
import { FlyInType } from '../../../common/store/structureDefinitions/modalState';
import { UserType } from '../../../common/store/structureDefinitions/userState';
import HeaderIconButton from '../HeaderIconButton/HeaderIconButton';
import type { SiteNavigationItem } from './FeaturedNavigation';

const OldSiteNavigation = React.lazy(() => import('../SiteNavigation/OldSiteNavigation'));
const SiteNavigation = React.lazy(() => import('../SiteNavigation'));

interface HeaderLeftNavProps {
    disableSiteNavigation: boolean;
    navigationItems: SiteNavigationItem[];
    logoColor: 'green' | 'white';
}

const useGlobalSiteNavigation = () => {
    const currentProductionUnitId = useAppSelector(storeSelectors.currentProductionUnit);

    const enabled =
        appConfig.coopSettings.featureToggles.enableGlobalSiteMenu ||
        appConfig.coopSettings.featureToggles.enableGlobalSiteMenuStoreIds?.includes(
            currentProductionUnitId,
        );

    return enabled;
};

const HeaderLeftNav = (props: React.PropsWithChildren<HeaderLeftNavProps>) => {
    const { isOpen, close, open } = useModal(FlyInType.SiteNavigation);

    // const isAbTest = useDynamicYieldAbTest('global-menu-abtest', 'global-menu-abtest');
    const isGlobalNavigationEnabled = useGlobalSiteNavigation();

    const { data: userType } = useUserTypeQuery();
    const isPunchout = userType === UserType.punchout;

    return (
        <>
            {!props.disableSiteNavigation && (
                <>
                    <HeaderIconButton
                        icon={Menu1Icon}
                        label="Meny"
                        onClick={open}
                        theme="greyBorder"
                    />
                    <FlyIn
                        isOpen={isOpen}
                        close={close}
                        sizePx={375}
                        alignment="left"
                        initialFocusOnDialog={false}
                    >
                        <React.Suspense
                            fallback={<OverlayLoader options={{ isOverlaySection: true }} />}
                        >
                            {isGlobalNavigationEnabled ? (
                                <SiteNavigation onClose={close} />
                            ) : (
                                // old crap
                                <OldSiteNavigation
                                    onClose={close}
                                    navigationItems={props.navigationItems}
                                />
                            )}
                        </React.Suspense>
                    </FlyIn>
                </>
            )}
            <a
                className={classNames(
                    'Header-logo',
                    props.logoColor === 'white' && 'Header-logo--white',
                    props.logoColor === 'green' && 'Header-logo--green',
                )}
                href={
                    appConfig.coopUserSettings.isShoppingForAnother || isPunchout
                        ? appConfig.coopSettings.ecommerce.url
                        : '/'
                }
                title="Till startsidan"
            >
                <Icon icon={CoopLogoIcon} />
            </a>
        </>
    );
};

export default HeaderLeftNav;
