/* eslint-disable react/jsx-props-no-spreading */
import type { FC } from 'react';
import * as React from 'react';

import StickyBottom from '../../../common/components/atoms/StickyBottom';
import CncFlyIn from '../../../common/components/molecules/CncFlyIn';
import useCurrentStoreSync from '../../../common/hooks/useCurrentStoreSync';
import { usePageConsumptionTracking } from '../../../common/hooks/usePageConsumptionTracking';
import { HeaderAlertsPortal } from '../HeaderAlerts';
import ImpersonationNotice from '../ImpersonationNotice';
import { useInitialCartFetch, useRedirectReasonHandler } from './CommonLayout.hooks';

interface CommonLayoutProps {}

/**
 * Contains common components and hooks that are common for all other layouts like EcommerceLayout, BaseLayout
 */
const CommonLayout: FC<React.PropsWithChildren<CommonLayoutProps>> = (props) => {
    useCurrentStoreSync();
    useInitialCartFetch();
    useRedirectReasonHandler();
    usePageConsumptionTracking();

    return (
        <>
            <HeaderAlertsPortal />

            {props.children}
            <StickyBottom usePortal>
                <ImpersonationNotice />
            </StickyBottom>
            <CncFlyIn />
        </>
    );
};

export default CommonLayout;
