import * as React from 'react';
import { useContext } from 'react';

import { ModalContextState } from './ModalContext';

interface ModalDescriptionProps extends React.ComponentPropsWithoutRef<'p'> {}

const ModalDescription = React.forwardRef<
    HTMLHeadingElement,
    React.PropsWithChildren<ModalDescriptionProps>
>(({ children, ...props }, ref) => {
    const modalContext = useContext(ModalContextState);

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <p ref={ref} {...props} id={modalContext?.descriptionId}>
            {children}
        </p>
    );
});

export default ModalDescription;
